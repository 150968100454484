import { v4 as uuidv4 } from 'uuid';
import type { GetState, SetState } from 'zustand';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface AppLocalKeyStore {
  browserId: string;
  updateBrowserId: () => void;
}

export const useAppLocalStore = create<AppLocalKeyStore>(
  persist(
    (set: SetState<AppLocalKeyStore>, get: GetState<AppLocalKeyStore>) => ({
      browserId: null,
      updateBrowserId: () => set(() => ({ browserId: uuidv4() })),
    }),
    {
      name: 'app-local-store',
      getStorage: () => localStorage,
    }
  )
);

if (useAppLocalStore.getState().browserId === null) {
  useAppLocalStore.getState().updateBrowserId();
}
