import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'user-pages';

// TODO: fill in missing translations
const translations = {
  title: 'Your pages',
  btnCreateNewPage: 'Create new page',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
