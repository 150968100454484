import { useBemCN } from '@gik/core/utils/bemBlock';
import SearchIcon from '@heroicons/react/solid/SearchIcon';
import React from 'react';
import type { InputProps } from '../Input';
import { Input } from '../Input';
import { SvgIcon } from '../SvgIcon/SvgIcon';

export interface ISearchInputProps extends InputProps {}

export function SearchInput({
  className,
  onValueChange,
  variant = 'transparent',
  ...otherProps
}: ISearchInputProps): React.ReactElement {
  const bem = useBemCN('search-input');

  const [active, setActive] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>();
  const valueRef = React.useRef<string>();
  valueRef.current = value;

  return (
    <Input
      pill
      placeholder="Search..."
      variant={variant}
      prepend={<SvgIcon {...bem('icon')} Icon={SearchIcon} onClick={() => setActive(!active)} />}
      onBlur={() => {
        if (!valueRef.current) setActive(false);
      }}
      onClear={() => setActive(false)}
      onFocus={() => setActive(true)}
      clearable
      active={active}
      value={value}
      {...otherProps}
      onValueChange={v => {
        setValue(v);
        onValueChange(v);
      }}
      {...bem(null, null, className)}
    />
  );
}
