import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'sign-in-flow';

const translations = {
  startContentTitle: 'Show your support',
  startContentCopyGeneral:
    'Get notified of updates, sign up to help, send needed items, leave notes of encouragement and more.',
  startContentCopyInKind: 'Sign up to help, receive updates, send gifts, post messages and more for ',

  loginContentTitle: '👋️ Welcome Back, {{supportersFirstName}}',
  loginContentCopy: 'Please enter your password below',
  deactivatedContentCopy: 'Your account is not active. Please use the button below to select a different email.',

  oauthContentCopy: `It looks like you previously used <span class="tw-capitalize">{{provider}}</span> to sign in to Give InKind`,

  signupContentTitle: '🤝 Nice to meet you',
  signupContentCopy1: `We didn't find an account for that email. Try a `,
  signupContentCopy2: 'different email',
  signupContentCopy3: ', or sign up using...',

  headerTitleStart: 'Sign Up or Sign In',
  headerTitleLogin: 'Sign In',
  headerTitleOAuth: 'Sign In',
  headerTitleSignup: 'Sign Up',
  headerTitleDeactivated: 'Account Deactivated',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
