import bemBlock from '@gik/core/utils/bemBlock';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { Image } from '@gik/ui/Image';
import { KeepProportions } from '@gik/ui/KeepProportions';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import PlusIcon from '@heroicons/react/outline/PlusIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import React from 'react';

export interface IGiftCardTileProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  thumbnail: string;
  addButton?: boolean;
  deleteButton?: boolean;
  selected?: boolean;
  onAdd?: () => void;
  onDelete?: () => void;
  onClickItem?: () => void;
}

export function GiftCardTile({
  name,
  thumbnail,
  addButton,
  deleteButton,
  className,
  selected,
  onAdd,
  onDelete,
  onClickItem,
  ...otherProps
}: IGiftCardTileProps): React.ReactElement {
  const bem = bemBlock('gift-card-tile');

  return (
    <div className={bem(null, [{ selected }], className)} {...otherProps}>
      <div className={bem('border')} />
      <div className={bem('inner')} onClick={() => onClickItem?.()}>
        <KeepProportions proportion={50 / 79}>
          <Image className={bem('image')} draggable={false} src={thumbnail} />
        </KeepProportions>
        <span className={bem('label')}>{HTMLParser({ rawHtml: name })}</span>

        {addButton && (
          <div
            className={bem('button', ['add'])}
            onClick={ev => {
              ev.stopPropagation();
              onAdd?.();
            }}
          >
            <SvgIcon Icon={PlusIcon} />
          </div>
        )}

        {deleteButton && (
          <div
            className={bem('button', ['delete'])}
            onClick={ev => {
              ev.stopPropagation();
              onDelete?.();
            }}
          >
            <SvgIcon Icon={TrashIcon} />
          </div>
        )}
      </div>
    </div>
  );
}
