import { timeoutDefaultValue } from '@gik/core/constants';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import type { HeadingLevel } from '@gik/ui/Heading';
import React from 'react';
import { AccordionItem } from './AccordionItem';

export interface AccordionItemType {
  title: React.ReactNode;
  content: React.ReactNode;
  isOpen?: boolean;
}

export type AccordionProps = {
  autoCloseItems?: boolean;
  items?: AccordionItemType[];
  dividers?: boolean;
  allToggled?: boolean;
  /**
   * Use this prop to force all items to recalculate height.
   * Useful when item's content height changes at some later point in time.
   * e.g. validation messages show/hide, collapsing on a breakpoint
   */
  recalculateHeightCallback?: (fn: () => void) => void;
  headingLevel?: HeadingLevel;
} & UIComponent;

function AccordionComp({
  children,
  autoCloseItems = true,
  className,
  items,
  dividers,
  allToggled,
  recalculateHeightCallback,
  headingLevel,
  ...otherProps
}: React.PropsWithChildren<AccordionProps>): React.ReactElement {
  const activeIndicesRef = React.useRef<number[]>([]);

  const blockRef = React.useRef<HTMLDivElement>(null);

  const bem = useBemCN('accordion');

  React.useEffect(() => {
    recalculateHeightCallback && recalculateHeightCallback(_recalculateHeight);
    // eslint-disable-next-line
  }, [recalculateHeightCallback]);

  // recalculate height for all expanded items
  function _recalculateHeight(timeout = 500) {
    blockRef.current &&
      Array.from(blockRef.current.childNodes).forEach((node, index) => {
        const el = node as HTMLDivElement;
        const mainEl = el.lastChild as HTMLDivElement;

        const itemIsOpen = activeIndicesRef.current.indexOf(index) > -1;

        if (mainEl && itemIsOpen) {
          mainEl.style.height = 'auto';

          setTimeout(() => {
            // NOTE: this line needs to be inside setTimeout too
            // transition takes time, let it run and then sample the height
            // most transitions are ~300ms, larger timeout is needed
            const contentHeight = mainEl.offsetHeight;
            mainEl.style.height = contentHeight + 'px';
          }, timeout);
        }
      });
  }

  function collapseAll() {
    if (!blockRef.current) {
      return;
    }

    Array.from(blockRef.current.childNodes).forEach(node => {
      const el = node as HTMLDivElement;
      const mainEl = el.lastChild as HTMLDivElement;
      if (mainEl) {
        mainEl.style.height = '0px';
      }
    });
    activeIndicesRef.current = [];
  }

  function openAll() {
    if (!blockRef.current) {
      return;
    }

    const newActiveIndices = [];

    Array.from(blockRef.current.childNodes).forEach((node, index) => {
      const el = node as HTMLDivElement;
      const mainEl = el.lastChild as HTMLDivElement;
      if (mainEl) {
        mainEl.style.height = 'auto';
        const contentHeight = mainEl.offsetHeight;
        mainEl.style.height = '0px';

        newActiveIndices.push(index);
        // NOTE: small timeout is needed to trigger the height transition
        setTimeout(() => {
          mainEl.style.height = contentHeight + 'px';
        }, timeoutDefaultValue);
      }
    });
    activeIndicesRef.current = newActiveIndices;
  }

  const handleToggle = React.useCallback(
    (ev: React.MouseEvent<HTMLDivElement>, index: number) => {
      const itemEl = (ev.currentTarget as HTMLDivElement).parentElement;
      const mainEl = itemEl.lastChild as HTMLDivElement;

      const activeIndices = activeIndicesRef.current;

      const itemIsOpen = activeIndices.indexOf(index) > -1;

      let newActiveIndices = activeIndices.concat([]);

      if (mainEl) {
        if (itemIsOpen) {
          if (autoCloseItems) return;
          mainEl.style.height = '0px';
          newActiveIndices.splice(newActiveIndices.indexOf(index), 1);
        } else {
          if (autoCloseItems) {
            collapseAll();
            newActiveIndices = [];
          }

          mainEl.style.height = 'auto';
          const contentHeight = mainEl?.offsetHeight;
          mainEl.style.height = '0px';

          // NOTE: small timeout is needed to trigger the height transition
          setTimeout(() => {
            mainEl.style.height = contentHeight + 'px';
          }, timeoutDefaultValue);
          newActiveIndices.push(index);
        }
      }

      activeIndicesRef.current = newActiveIndices;
    },
    [autoCloseItems]
  );

  React.useEffect(() => {
    if (allToggled === false) {
      collapseAll();
    } else if (allToggled === true) {
      openAll();
    }
  }, [allToggled]);

  return (
    <div {...bem(null, [{ dividers }], className)} {...otherProps} ref={blockRef}>
      {items?.map((item, index) => {
        const isOpen = activeIndicesRef.current.indexOf(index) > -1;

        return (
          <AccordionItem
            key={index}
            header={item.title}
            isOpen={isOpen}
            onToggle={ev => handleToggle(ev, index)}
            headerAsHeading={headingLevel !== undefined}
            headingLevel={headingLevel}
          >
            {item.content}
          </AccordionItem>
        );
      })}
      {!items &&
        React.Children.map(children, (child, index) => {
          if (!React.isValidElement(child)) {
            return null;
          }

          return (
            <>
              {dividers && index > 0 && <hr />}
              {React.cloneElement(child, {
                // @ts-ignore
                onToggle: ev => handleToggle(ev, index),
                isOpen: activeIndicesRef.current.indexOf(index) > -1,
              })}
            </>
          );
        })}
    </div>
  );
}

export const Accordion = AccordionComp;
