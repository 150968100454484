import { useWishlistProducts } from '@gik/api/inkinds/wishlistProducts';
import { productIsGiftcard } from '@gik/checkout/utils/productUtils';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import { CartProductTile } from '@gik/shop/components/CartProductTile/CartProductTile';
import he from 'he';
import React from 'react';

export type AddFromWishtListSectionProps = UIComponent & {
  inkindRouteId: string;
  pageName: string;
  selectedId?: number;
  buttonsPortal?: HTMLElement;
  buttons?: (isFormValid: boolean) => React.ReactNode;
  onSelect?: (id: number) => void;
};

export function AddFromWishtlistSection({
  children,
  className,
  inkindRouteId,
  selectedId,
  pageName,
  onSelect,
  buttons,
  buttonsPortal,
  ...otherProps
}: React.PropsWithChildren<AddFromWishtListSectionProps>): React.ReactElement {
  const bem = useBemCN('add-from-wishlist-section');

  const {
    data: wishListProducts,
    isValidating: isLoadingWishlistProducts,
    error: wishListProductsError,
  } = useWishlistProducts(inkindRouteId);

  const wishListProductsFiltered = React.useMemo(() => {
    return wishListProducts?.filter(item => item.checkoutType === 'tango-card' || item.checkoutType === 'perfectgift');
  }, [wishListProducts]);

  // auto select first item
  // React.useEffect(() => {
  //   if (wishListProductsFiltered?.length == 1) {
  //     onSelect(wishListProductsFiltered[0].id);
  //   }
  // }, [onSelect, wishListProductsFiltered]);

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <span className="gik-modal-add-from-title">{he.decode(pageName)}&apos;s Wishlist</span>
      <p>Which Gift Card would you like to add to your order?</p>
      <div {...bem(null, 'tiles', 'tw-flex tw-flex-col tw-gap-2')}>
        {wishListProductsFiltered?.map(item => {
          return (
            <CartProductTile
              key={item.id}
              selectable
              selected={selectedId === item.id}
              direction="row"
              containImage={productIsGiftcard(item)}
              imageUrl={item.gridImage}
              onClick={() => onSelect?.(item.id)}
              title={item.name}
            />
          );
        })}
      </div>

      {renderPortal?.(buttons && buttons?.(!isNaN(selectedId)), () => buttonsPortal)}
    </div>
  );
}
