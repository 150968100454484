import { timeoutDefaultValue } from '@gik/core/constants';
import bemBlock from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { Drawer } from '@gik/ui/Drawer';
import type { ModalRefProps } from '@gik/ui/Modal';
import { Modal } from '@gik/ui/Modal';
import type { ModalProps } from '@gik/ui/Modal/types/ModalProps';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDialogStore } from './context';
import type {
  DialogRenderCallback,
  DialogResponseType,
  IDialog,
  IDialogManagerProps,
  IDialogRenderProps,
} from './DialogTypes';
import { translationKeys } from './i18n/en';

/**
 * Dialog Manager
 *
 * Takes care of opening and closing dialogs.
 */
export function Dialogs(_props: IDialogManagerProps): React.ReactElement {
  const dialogsStore = useDialogStore();
  const modalItems = dialogsStore.dialogs;

  const wrapper = (
    <>
      {modalItems?.map((modalItem, index) => {
        return <Dialog key={modalItem.id} item={modalItem} index={index} /*onClose={onClose}*/ />;
      })}
    </>
  );

  return wrapper;
}

interface IDialogProps {
  item: IDialog;
  index: number;
}

const closeConfirmCopy = 'Are you sure you want to close this dialog?';

function Dialog({ item, index }: IDialogProps) {
  const { t } = useTranslation();
  const contentRef = React.useRef();
  const [footerEl, setFooterEl] = React.useState<HTMLDivElement>();
  const [headerEl, setHeaderEl] = React.useState<HTMLDivElement>();
  const footerRef = React.useRef<HTMLDivElement>();
  footerRef.current = footerEl;
  const headerRef = React.useRef<HTMLDivElement>();
  headerRef.current = headerEl;

  const dialogsStore = useDialogStore();

  // hack to fix footer ref by re-rendering dialog
  // modal footer needs to be rendered once to assign the ref before buttons can be portalled
  const [, fixRefs] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      fixRefs(true);
      item.onReady?.(renderProps);
    }, timeoutDefaultValue);
    // eslint-disable-next-line
  }, []);

  // this allows a dialog's title to be updated dynamically
  const [itemTitle, setItemTitle] = React.useState<string>();
  const [itemClosable, setItemClosable] = React.useState<boolean | undefined>(undefined);

  // React.useEffect(() => {
  //   if (!itemClosable) {
  //     window.onbeforeunload = function (e) {
  //       e = e || window.event;
  //
  //       // For IE and Firefox prior to version 4
  //       if (e) {
  //         e.returnValue = closeConfirmCopy;
  //       }
  //
  //       // For Safari
  //       return closeConfirmCopy;
  //     };
  //   }
  //   return () => {
  //     window.onbeforeunload = undefined;
  //   };
  // }, [itemClosable]);

  const [onBack, setOnBack] = React.useState<() => void>();
  const [footerClass, setFooterClass] = React.useState<string>('test');

  const modalRef = React.useRef<ModalRefProps>();

  //const bem = bemBlock('dialogs');
  const modalBem = bemBlock('modal');

  function removeDialog(item: IDialog) {
    useDialogStore.getState().remove(item.id);
  }

  async function handleClose(item: IDialog) {
    let closeResponse: boolean;
    if (item.onClose) {
      closeResponse = await item.onClose(item.response);
      if (closeResponse === false) return false;
    }
    useDialogStore.getState().close(item.id);
    return null;
  }

  function handleAfterClose(item: IDialog) {
    if (item.onPromiseResolve) item.onPromiseResolve(item.response);
    if (item.onAfterClose) item.onAfterClose();
    removeDialog(item);
    //onClose(item.id);
  }

  function handleOK(item: IDialog) {
    item.response = true;
    handleClose(item);
  }

  function handleCancel(item: IDialog) {
    item.response = false;
    handleClose(item);
  }

  const extraProps: Partial<ModalProps> = item.modalProps || {};
  let shouldCloseOnOverlayClick = item.shouldCloseOnOverlayClick !== undefined ? item.shouldCloseOnOverlayClick : true;

  // generate generic buttons if none were provided
  function generateButtons() {
    const generatedButtons = [];
    switch (item.type) {
      case 'alert':
        shouldCloseOnOverlayClick = false;
        generatedButtons.push(
          <Button key="ok" variant="primary" onClick={() => handleOK(item)} {...item.okButtonProps}>
            {item.okText || t(translationKeys.alertAcceptButton)}
          </Button>
        );
        break;
      case 'error':
        generatedButtons.push(
          <Button key="ok" variant="danger" onClick={() => handleOK(item)} {...item.okButtonProps}>
            {item.okText || t(translationKeys.errorAcceptButton)}
          </Button>
        );
        break;
      case 'warn':
        generatedButtons.push(
          <Button key="ok" variant="warning" onClick={() => handleOK(item)} {...item.okButtonProps}>
            {item.okText || t(translationKeys.warnAcceptButton)}
          </Button>
        );
        break;
      case 'success':
        generatedButtons.push(
          <Button key="ok" variant="success" onClick={() => handleOK(item)} {...item.okButtonProps}>
            {item.okText || t(translationKeys.successAcceptButton)}
          </Button>
        );
        break;
      case 'info':
        generatedButtons.push(
          <Button key="ok" variant="primary" onClick={() => handleOK(item)} {...item.okButtonProps}>
            {item.okText || t(translationKeys.infoAcceptButton)}
          </Button>
        );
        break;
      case 'confirm':
        shouldCloseOnOverlayClick = false;
        generatedButtons.push(
          <Button key="cancel" variant="default" onClick={() => handleCancel(item)} {...item.cancelButtonProps}>
            {item.cancelText || t(translationKeys.confirmCancelButton)}
          </Button>
        );
        generatedButtons.push(
          <Button key="ok" variant="primary" onClick={() => handleOK(item)} {...item.okButtonProps}>
            {item.okText || t(translationKeys.confirmAcceptButton)}
          </Button>
        );
        break;
    }
    return generatedButtons;
  }

  function isSystemDialog(item: IDialog): boolean {
    return ['alert', 'error', 'warn', 'success', 'confirm', 'info'].indexOf(item.type) > -1;
  }

  function generateTitle(item: IDialog): string {
    let title: string;
    switch (item.type) {
      case 'alert':
        title = item.title || t(translationKeys.alertTitle);
        break;
      case 'error':
        title = item.title || t(translationKeys.errorTitle);
        break;
      case 'warn':
        title = item.title || t(translationKeys.warnTitle);
        break;
      case 'success':
        title = item.title || t(translationKeys.successTitle);
        break;
      case 'confirm':
        title = item.title || t(translationKeys.confirmTitle);
        break;
      case 'info':
        title = item.title || t(translationKeys.infoTitle);
        break;
    }
    return title;
    // return <h3 className={modalBem('header')}>{title || item.title}</h3>;
  }

  const closeWithCustomResponse = (response?: DialogResponseType) => {
    item.response = response;
    handleClose(item);
  };

  const scrollToTop = React.useCallback(() => {
    modalRef.current?.scrollToTop();
  }, []);

  const renderProps: IDialogRenderProps = {
    close: closeWithCustomResponse,
    setTitle: setItemTitle,
    setClosable: setItemClosable,
    scrollToTop,
    setOnBack,
    footerClass,
    setFooterClass,
    footerRef,
    headerRef,
    contentRef,
  };

  // if the header is not a valid react element we assume it to be a callback function that accepts renderProps
  if (item.header && !React.isValidElement(item.header)) {
    item.header = (item.header as DialogRenderCallback)(renderProps);
  }

  // by default system dialogs should have a centered title
  if (item.centeredTitle === undefined && isSystemDialog(item)) {
    extraProps.centeredTitle = true;
  }

  // if the footer is not a valid react element we assume it to be a callback function that accepts renderProps
  if (item.footer && !React.isValidElement(item.footer)) {
    item.footer = (item.footer as DialogRenderCallback)(renderProps);
  }

  // if no footer was provided then use the buttons property to display buttons or auto generate them
  // if you wish to not have an autogenerated footer, explicitly pass null.
  if (item.footer === undefined) {
    // parse `buttons` property to render custom buttons
    let customButtons: React.ReactNode;
    if (item.buttons) {
      if (React.isValidElement(item.buttons)) {
        customButtons = item.buttons as React.ReactNode;
      } else {
        customButtons = (item.buttons as DialogRenderCallback)(renderProps);
      }
    }

    // if no custom buttons are passed then autoGenerated buttons will be used if the dialog type matches
    const autoGeneratedButtons = generateButtons();

    if (customButtons || autoGeneratedButtons?.length) {
      extraProps.footer = (
        // TODO: instead of section use ModalButtonFooter (use forwardref)
        // this way we can have a consistent footer style across all modals
        <section ref={footerRef} className={modalBem('toolbar')}>
          {customButtons || autoGeneratedButtons}
        </section>
      );
    }
  }

  const modalClassNames = modalBem(
    undefined,
    [
      { centered: isSystemDialog(item) || item.centered },
      { autowidth: item.autowidth },
      { system: !item.disableSystemStyling && isSystemDialog(item) },
    ],
    [item.className]
  );

  const contentIsValidElement = React.isValidElement(item.content) || typeof item.content === 'string';

  const modalSheet = dialogsStore.sheet;

  const modalSheetProps = typeof modalSheet === 'function' ? modalSheet() : modalSheet;

  const content = contentIsValidElement ? item.content : (item.content as DialogRenderCallback)(renderProps);

  const modalSheetClassName = modalBem('drawer', null, [modalSheetProps?.className, 'gik-modal-drawer']);

  const contentWrapper = (
    <>
      {content}

      {/* The modalsheet should only show on the first (main) modal */}
      {modalSheet && index === 0 && (
        <Drawer
          placement="bottom"
          variant="neutral"
          shadow
          {...modalSheetProps}
          className={modalSheetClassName}
          isOpen={dialogsStore.sheetIsOpen}
          onClose={async () => {
            if (modalSheetProps.onClose) {
              const response = await modalSheetProps.onClose();
              // prevent sheet from closing if response from custom onClose is false
              if (response === false) return false;
            }

            dialogsStore.closeModalSheet();
            return true;
          }}
        />
      )}
    </>
  );

  function onFooterRef(ref: HTMLDivElement) {
    footerRef.current = ref;
    setFooterEl(ref?.firstElementChild as HTMLDivElement);
  }
  function onHeaderRef(ref: HTMLDivElement) {
    headerRef.current = ref;
    setHeaderEl(ref);
  }

  return (
    <Modal
      {...item}
      ref={modalRef}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      disableBackdrop={item.useBackdrop === false}
      {...extraProps}
      onBack={onBack}
      onClose={async () => handleClose(item)}
      onAfterClose={() => handleAfterClose(item)}
      isOpen={item.open}
      closable={
        itemClosable !== undefined
          ? itemClosable
          : extraProps.closable !== undefined
          ? extraProps.closable
          : item.closable
      }
      className={modalClassNames}
      closeAnimationLength={350}
      contentRef={contentRef}
      footerClass={footerClass}
      onFooterRef={onFooterRef}
      onHeaderRef={onHeaderRef}
      pages={[
        {
          title: itemTitle || item.title || generateTitle(item),
          content: contentWrapper,
        },
      ]}
    />
  );
}
