import { PremiumPageUpgradeModalContent } from '@gik/checkout/components/PremiumPageUpgradeModal/PremiumPageUpgradeModal';
import { useBemCN } from '@gik/core/utils/bemBlock';
import React from 'react';

export default function InkindPageUpgradeSection() {
  const bem = useBemCN('page-settings-upgrade-section');
  return (
    <section {...bem()}>
      <div>
        <PremiumPageUpgradeModalContent initiatedOn="inkindPageSettings" variant="generic" />
      </div>
    </section>
  );
}
