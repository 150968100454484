// reaction emojis
import Celebrate from 'openmoji/color/svg/1F389.svg';
import ThumbsUp from 'openmoji/color/svg/1F44D.svg';
import ThumbsDown from 'openmoji/color/svg/1F44E.svg';
import ClappingHands from 'openmoji/color/svg/1F44F.svg';
import FlexBiceps from 'openmoji/color/svg/1F4AA.svg';
import GrinningFace from 'openmoji/color/svg/1F600.svg';
import BeamingFaceWithSmilingEyes from 'openmoji/color/svg/1F601.svg';
import GrinningFaceWithBigEyes from 'openmoji/color/svg/1F603.svg';
import GrinningFaceWithSmilingEyes from 'openmoji/color/svg/1F604.svg';
import SmilingFaceWithSmilingEyes from 'openmoji/color/svg/1F60A.svg';
import SmilingFaceWithHeartEyes from 'openmoji/color/svg/1F60D.svg';
import PoutingFace from 'openmoji/color/svg/1F621.svg';
import Cryingface from 'openmoji/color/svg/1F622.svg';
import HushedFace from 'openmoji/color/svg/1F62F.svg';
import SlightlySmilingFace from 'openmoji/color/svg/1F642.svg';
import FoldedHands from 'openmoji/color/svg/1F64F.svg';
import ThinkingFace from 'openmoji/color/svg/1F914.svg';
import HuggingFace from 'openmoji/color/svg/1F917.svg';
import SickFace from 'openmoji/color/svg/1F922.svg';
import SmilingFaceWithHearts from 'openmoji/color/svg/1F970.svg';
// tip emojis
import PleadingFace from 'openmoji/color/svg/1F97A.svg';
import Heart from 'openmoji/color/svg/2764.svg';
import React from 'react';

export const TipEmojis = {
  'pleading-face': PleadingFace,
  'slightly-smiling-face': SlightlySmilingFace,
  'smiling-face-with-smiling-eyes': SmilingFaceWithSmilingEyes,
  'grinning-face': GrinningFace,
  'grinning-face-with-big-eyes': GrinningFaceWithBigEyes,
  'grinning-face-with-smiling-eyes': GrinningFaceWithSmilingEyes,
  'beaming-face-with-smiling-eyes': BeamingFaceWithSmilingEyes,
  'hugging-face': HuggingFace,
  'smiling-face-with-heart-eyes': SmilingFaceWithHeartEyes,
  'smiling-face-with-hearts': SmilingFaceWithHearts,
  'thinking-face': ThinkingFace,
  'clapping-hands': ClappingHands,
  'hushed-face': HushedFace,
};

export const ReactionEmojis = {
  love: Heart,
  like: ThumbsUp,
  dislike: ThumbsDown,
  haha: GrinningFaceWithSmilingEyes,
  strong: FlexBiceps,
  prayers: FoldedHands,
  sad: Cryingface,
  angry: PoutingFace,
  celebrate: Celebrate,
  sick: SickFace,
};

export const EmojiMap = {
  ...TipEmojis,
  ...ReactionEmojis,
};

export type OpenMojiKey = keyof typeof EmojiMap;
export type ReactionEmojiKey = keyof typeof ReactionEmojis;

export interface IOpenMojiProps extends React.SVGAttributes<SVGElement> {
  name: OpenMojiKey;
}

export const OpenMoji: React.FC<IOpenMojiProps> = ({ name, ...rest }): JSX.Element => {
  if (!(name in EmojiMap)) throw new Error(`OpenMoji ${name} does not exist.`);

  const Icon = EmojiMap[name];
  return <Icon {...rest} />;
};
