import { SignInFlowStartContentFormInputNames } from '@gik/auth/enums/SignInFlowStartContentFormInputNames';
import type { FormSchemaEntry } from '@gik/ui/Form';

export const signInFlowStartContentFormSchema: FormSchemaEntry[] = [
  {
    placeholder: 'Email address',
    name: SignInFlowStartContentFormInputNames.EmailAddress,
    type: 'email',
    required: true,
    props: {
      variant: 'default-solid',
      autoFocus: true,
    },
  },
];
