import type { PaginationParams } from '@gik/api/pagination';
import { SORT_DIRECTION } from '@gik/api/pagination';
import type { APIResponseInterface, BaseAPIResponseData, BaseAPIResponseHeaders } from '@gik/core/api/BaseAPIConfig';
import { timeoutDefaultValue } from '@gik/core/constants';
import type { GIKApiError } from '@gik/core/models/gik/APIError';
import bemBlock from '@gik/core/utils/bemBlock';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import SortAscendingIcon from '@heroicons/react/solid/SortAscendingIcon';
import SortDescendingIcon from '@heroicons/react/solid/SortDescendingIcon';
import { AnimatePresence } from 'framer-motion';
import dynamic from 'next/dynamic';
import React from 'react';
import type { ScrollbarProps } from 'react-custom-scrollbars';
import type {
  Cell,
  CellProps,
  Column,
  ColumnInterface,
  Filters,
  HeaderProps,
  Hooks,
  PluginHook,
  Row,
  SortingRule,
  TableInstance,
  UseExpandedHooks,
  UseExpandedOptions,
  UseExpandedRowProps,
  UseFiltersColumnOptions,
  UseFiltersColumnProps,
  UseFiltersInstanceProps,
  UseFiltersOptions,
  UseFiltersState,
  UseGlobalFiltersColumnOptions,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersOptions,
  UseGlobalFiltersState,
  UseGroupByColumnOptions,
  UsePaginationInstanceProps,
  UsePaginationState,
  UseRowSelectInstanceProps,
  UseRowSelectOptions,
  UseRowSelectRowProps,
  UseRowSelectState,
  UseRowStateOptions,
  UseSortByColumnOptions,
  UseSortByColumnProps,
  UseSortByHooks,
  UseSortByOptions,
  UseSortByState,
  UseTableColumnProps,
  UseTableOptions,
} from 'react-table';
import {
  useAbsoluteLayout,
  useBlockLayout,
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { Checkbox } from '../Checkbox';
import { LoadingSpinner } from '../LoadingSpinner';
import { SvgIcon } from '../SvgIcon';
import type { UISize } from '../types';
import { AnimateHeight } from './AnimateHeight';
import { fuzzyTextFilterFn, GlobalFilter } from './TableFilters';
import { TablePagination } from './TablePagination';
import { TableRemotePagination } from './TableRemotePagination';
import type { UITableVariant } from './types';
// Redeclare forwardRef
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

const Scrollbars = dynamic<ScrollbarProps>(() => import('react-custom-scrollbars').then(mod => mod.Scrollbars));

export interface TableOptionsWithPlugins<D extends object = {}>
  extends UseExpandedOptions<D>,
    UseFiltersOptions<D>,
    UseFiltersColumnOptions<D>,
    UseGlobalFiltersColumnOptions<D>,
    UseGroupByColumnOptions<D>,
    UseGlobalFiltersOptions<D>,
    UseTableOptions<D>,
    UseSortByOptions<D>,
    UseRowSelectOptions<D>,
    UseRowStateOptions<D> {}

export type ColumnWithPlugins<T extends Object> = Column<T> &
  ColumnInterface &
  UseSortByColumnOptions<T> & {
    Header: ((expandedProps: UseExpandedHooks<T>) => React.ReactNode) | string;
  } & {
    variant?: UITableVariant;
    style?: React.CSSProperties;
    disableSortBy?: boolean;
    noPad?: boolean;
    center?: boolean;
    Filter?: React.ReactNode;
    filter?: string;
  } & {
    Cell?: (props: React.PropsWithChildren<CellProps<T, string>>) => JSX.Element | string | number;
  };

export type ColumnWithAllPlugins<T extends object> = ColumnWithPlugins<T> &
  UseTableColumnProps<T> &
  UseSortByHooks<T> &
  UseSortByColumnProps<T> &
  UseFiltersColumnProps<T>;

export type GikTableState<T extends object> = UseGlobalFiltersState<T> &
  UsePaginationState<T> &
  UseSortByState<T> &
  UseFiltersState<T> &
  UseRowSelectState<T>;

export type TableInstanceWithPlugins<T extends Object> = TableInstance<T> &
  UsePaginationInstanceProps<T> &
  UseFiltersInstanceProps<T> &
  UseGlobalFiltersInstanceProps<T> & {
    globalFilter: string;
    state: UseGlobalFiltersState<T> &
      UsePaginationState<T> &
      UseSortByState<T> &
      UseFiltersState<T> &
      UseRowSelectState<T>;
  };
export type RowWithPlugins<T extends Object> = Row<T> & UseExpandedRowProps<T> & UseRowSelectRowProps<T>;

export type TableExpandedState = {
  rowId: string;
  expanded: boolean;
};

export interface SelectedRowsIdsType {
  [key: string]: boolean;
}

export interface ITableProps<T extends Object = {}, D extends BaseAPIResponseData = {}>
  extends React.HTMLAttributes<HTMLTableElement> {
  data?: T[];
  columns?: ColumnWithPlugins<T>[];
  fullWidth?: boolean;
  fullHeight?: boolean;
  scrollable?: boolean;
  horScroll?: boolean;
  hasStickyHeader?: boolean;
  hasFlexLayout?: boolean;
  hasAbsoluteLayout?: boolean;
  hasFixedLayout?: boolean;
  hasBlockLayout?: boolean;
  hasMobileCards?: boolean;
  hideGlobalFilter?: boolean;
  hasGlobalFilter?: boolean;
  hasFilters?: boolean;
  hasAdvancedFilters?: boolean;
  hasSortBy?: boolean;
  hasExpandableRows?: boolean;
  hasCustomScrollbars?: boolean;
  hasPagination?: boolean;
  hasRemotePagination?: boolean;
  hasRowClick?: boolean;
  hasRowSelect?: boolean;
  hideHeaders?: boolean;
  truncate?: boolean;
  loading?: boolean;
  usePaginationHeight?: boolean;
  expanded?: TableExpandedState;
  autoResetExpanded?: boolean;
  selectedRowIds?: SelectedRowsIdsType;
  perPage?: number;
  globalFilter?: string;
  onRowClick?(item: RowWithPlugins<T>, index: number): void;
  customToolbar?(props: TableInstanceWithPlugins<T>, searchProps: PaginationParams): React.ReactNode;
  customHeader?(props: TableInstanceWithPlugins<T>): React.ReactNode;
  customFooter?(props: TableInstanceWithPlugins<T>): React.ReactNode;
  customDetailsDisplay?(props: TableInstanceWithPlugins<T>): React.ReactElement;
  customEmptyFilterView?(props: TableInstanceWithPlugins<T>, globalFilter?: string): React.ReactElement;
  customEmptyView?(props: TableInstanceWithPlugins<T>): React.ReactElement;
  rowSelectToolbar?(props: TableInstanceWithPlugins<T>): React.ReactElement;
  initialPageIndex?: number;
  renderRowSubComponent?(props: { row: RowWithPlugins<T> }): React.ReactNode;
  caption?: React.ReactNode;
  variant?: UITableVariant;
  size?: UISize;
  globalFilterPlaceholder?: string;
  globalFilterCollapse?: boolean;
  globalFilterBefore?: React.ReactNode;
  globalFilterAfter?: React.ReactNode;
  bodyMaxHeight?: string;
  headerPortal?: () => HTMLElement;
  page?: number;
  pages?: number;
  columnFilters?: (stateFilters: Filters<T>) => object;
  fetch?: (data: T[], paginationProps: PaginationParams) => APIResponseInterface<D>;
  onFetchReady?: (response: APIResponseInterface<{}>) => void;
  onChangeSort?: (sortBy: SortingRule<T>[]) => void;
  onRowSelect?(selectedRowIds: SelectedRowsIdsType);
  // onNext?: () => void;
  // onPrev?: () => void;
  // onGoto?: (num: number) => void;
  // onChangePageSize?: (num: number) => void;
}

export interface TableRefProps<D extends object = BaseAPIResponseData> {
  mutate: () => void;
  response: APIResponseInterface<{}, GIKApiError>;
  resHeaders: BaseAPIResponseHeaders;
  resData: D[];
  props: TableInstanceWithPlugins<D>;
  // rows: Row<D>;
}

function TableComp<T extends object>(
  {
    data,
    columns,
    className,
    fullWidth,
    fullHeight,
    scrollable,
    horScroll,
    hasStickyHeader,
    hasFlexLayout,
    hasFixedLayout,
    hasAbsoluteLayout,
    hasBlockLayout,
    hideHeaders,
    loading,
    // expanded,
    autoResetExpanded,
    // hasMobileCards,
    hasGlobalFilter,
    hasFilters,
    hasAdvancedFilters,
    hasSortBy,
    hasExpandableRows,
    hasPagination,
    hasRemotePagination,
    hasRowClick,
    hasCustomScrollbars,
    hasRowSelect,
    hideGlobalFilter,
    globalFilter,
    globalFilterPlaceholder = 'Search...',
    globalFilterCollapse,
    globalFilterBefore,
    globalFilterAfter,
    usePaginationHeight,
    caption,
    variant,
    columnFilters,
    size,
    truncate,
    customToolbar,
    customHeader,
    customFooter,
    customDetailsDisplay,
    customEmptyFilterView,
    customEmptyView,
    rowSelectToolbar,
    selectedRowIds,
    perPage = 10,
    initialPageIndex = 0,
    renderRowSubComponent,
    bodyMaxHeight,
    headerPortal,
    onRowClick,
    fetch = () => null,
    onFetchReady,
    onChangeSort,
    onRowSelect,
    ...otherProps
  }: ITableProps<T>,
  ref: React.MutableRefObject<TableRefProps<T>>
): React.ReactElement {
  const bem = bemBlock('table');

  const [_perPage, setPerPage] = React.useState<number>(perPage);
  const [page, setPage] = React.useState<number>(0);
  const [pages, setPages] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  // NOTE: sortBy stores both the sort id and direction
  const [sortBy, setSortBy] = React.useState<SortingRule<T>[]>([]);
  const [fetchError, setFetchError] = React.useState<string>();
  const [fetchReady, setFetchReady] = React.useState<boolean>(false);
  const [_filters, _setFilters] = React.useState<Filters<T>>();
  const [_tableData, _setTableData] = React.useState<T[]>(data || ([] as T[]));
  const [_globalFilter, _setGlobalFilter] = React.useState<string>(globalFilter);
  const _globalFilterRef = React.useRef<string>();

  const _propsRef = React.useRef<TableInstanceWithPlugins<T>>();
  // prettier-ignore
  const _stateRef =
    React.useRef<
      UseGlobalFiltersState<T> & UsePaginationState<T> & UseSortByState<T> & UseFiltersState<T> & UseRowSelectState<T>
    >();

  React.useImperativeHandle(ref, () => ({
    mutate: () => _response.mutate?.(),
    response: _response,
    resData: _resData,
    resHeaders: _resHeaders,
    props: _propsRef.current,
  }));

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // By default columns do not have a filter
      // Filter: DefaultColumnFilter,
    }),
    []
  );

  const plugins: PluginHook<T>[] = React.useMemo<PluginHook<T>[]>(() => {
    const plugins: PluginHook<T>[] = [];
    if (hasFlexLayout) plugins.push(useFlexLayout);
    if (hasAbsoluteLayout) plugins.push(useAbsoluteLayout);
    if (hasBlockLayout) plugins.push(useBlockLayout);
    if (hasStickyHeader) plugins.push(useSticky);
    if (hasFilters) plugins.push(useFilters);
    if (hasGlobalFilter) plugins.push(useGlobalFilter);
    if (hasSortBy) plugins.push(useSortBy);
    if (hasExpandableRows) plugins.push(useExpanded);
    if (hasPagination) plugins.push(usePagination);
    if (hasRowSelect) {
      plugins.push(useRowSelect);

      plugins.push((hooks: Hooks<T>) => {
        hooks.visibleColumns.push(columns => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            width: '40px',
            Header: (props: React.PropsWithChildren<HeaderProps<T>> & UseRowSelectInstanceProps<T>) => {
              const toggleProps = hasPagination
                ? props.getToggleAllPageRowsSelectedProps
                : props.getToggleAllRowsSelectedProps;

              const checkboxProps = toggleProps?.();

              return (
                <div>
                  <Checkbox
                    {...checkboxProps}
                    onChange={event => {
                      // FIXME: typings
                      checkboxProps.onChange(event as unknown as React.ChangeEvent<Element>);
                      setTimeout(() => {
                        onRowSelect?.(_stateRef.current.selectedRowIds);
                      }, timeoutDefaultValue);
                    }}
                  />
                </div>
              );
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              const checkboxProps = row.getToggleRowSelectedProps?.();
              return (
                <div>
                  <Checkbox
                    {...checkboxProps}
                    onChange={(value: boolean) => {
                      checkboxProps.onChange(value);
                      setTimeout(() => {
                        onRowSelect?.(_stateRef.current.selectedRowIds);
                      }, timeoutDefaultValue);
                    }}
                  />
                </div>
              );
            },
          },
          ...columns,
        ]);
      });
    }
    return plugins;
  }, [
    hasAbsoluteLayout,
    hasBlockLayout,
    hasExpandableRows,
    hasFilters,
    hasFlexLayout,
    hasGlobalFilter,
    hasPagination,
    hasRowSelect,
    hasSortBy,
    hasStickyHeader,
    onRowSelect,
  ]);

  // FIXME: fix typings
  // @ts-ignore
  const tableOptions: TableOptionsWithPlugins<T> = React.useMemo(() => {
    return {
      columns: columns || [],
      data: _tableData || [],
      // @ts-ignore
      defaultColumn,
      manualFilters: !!fetch,
      autoResetFilters: false,
      enableRowSelection: hasRowSelect,
      filterTypes,
      autoResetExpanded,
      initialState: {
        sortBy,
        pageIndex: initialPageIndex,
        pageSize: _perPage,
        selectedRowIds: selectedRowIds || {},
        globalFilter: fetch ? null : _globalFilter,
      } as Partial<GikTableState<T>>,
    };
  }, [
    _globalFilter,
    _perPage,
    _tableData,
    autoResetExpanded,
    columns,
    defaultColumn,
    fetch,
    filterTypes,
    hasRowSelect,
    initialPageIndex,
    selectedRowIds,
    sortBy,
  ]);

  const tableProps = useTable<T>(tableOptions, ...plugins) as TableInstanceWithPlugins<T>;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page: pageData,
    prepareRow,
    state,
    visibleColumns,
    setGlobalFilter,
    preGlobalFilteredRows,
    pageOptions,
  } = tableProps;

  const stateSortBy = state.sortBy;
  const stateFilters = state.filters;

  _propsRef.current = tableProps;
  _stateRef.current = state;

  const _columnFilters = columnFilters?.(stateFilters);

  // noop function for swr
  function temp<D>() {
    return {} as APIResponseInterface<D>;
  }

  const fetchFn = fetch || temp;

  const _searchProps: PaginationParams = React.useMemo(
    () => ({
      page,
      per_page: _perPage,
      search: _globalFilter,
      sortBy: sortBy?.[0]?.id,
      sortDirection: sortBy?.[0]?.id ? (sortBy?.[0]?.desc ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC) : undefined,
      ..._columnFilters,
    }),
    [_perPage, _globalFilter, sortBy, page, _columnFilters]
  );

  const _response = fetchFn(data, _searchProps);
  const _resData = _response?.data as T[];
  const _resHeaders = _response?.headers;

  React.useEffect(() => {
    if (data) return;

    if (_resData && !Array.isArray(_resData)) {
      setFetchError((_resData as Error)?.message);
      _setTableData([] as T[]);
      return;
    }

    if (_resData) {
      _setTableData(_resData as T[]);
    }
  }, [_resData, data, setFetchError]);

  React.useEffect(() => {
    setSortBy(stateSortBy);
    onChangeSort?.(stateSortBy);
  }, [onChangeSort, stateSortBy]);

  React.useEffect(() => {
    if (_response?.data && !fetchReady) {
      setFetchReady(true);
      onFetchReady?.(_response);
    }
  }, [_response, fetchReady, onFetchReady]);

  React.useEffect(() => {
    if (!_tableData || !_resHeaders) return;

    let pages: number;
    let total = _resHeaders?.['x-total'];
    if (_tableData && Array.isArray(_tableData)) {
      pages = _resHeaders ? _resHeaders['x-total-pages'] : _tableData?.length / perPage;
      total = _resHeaders?.['x-total'];
    }

    setTotal(total);
    setPages(pages);
  }, [_resHeaders, _tableData, perPage]);

  React.useEffect(() => {
    _globalFilterRef.current = globalFilter;
    _setGlobalFilter(globalFilter);
    setGlobalFilter?.(globalFilter);
    setPage(0);
  }, [globalFilter, setGlobalFilter]);

  const isLoading = fetch && !data && (loading || (fetch && !_resData));

  const bodyStyle: React.CSSProperties = {};
  if (bodyMaxHeight) {
    bodyStyle.height = bodyMaxHeight;
  }

  const variants = {
    open: {
      opacity: 1,
      height: 'auto',
    },
    collapsed: { opacity: 0, height: '1px' },
  };

  const rowSet = (pageData || rows) as RowWithPlugins<T>[];

  const handleNext = React.useCallback(() => {
    if (page >= pages - 1) return;
    setPage(page + 1);
  }, [page, pages]);

  const handlePrev = React.useCallback(() => {
    if (page <= 0) return;
    setPage(page - 1);
  }, [page]);

  const handleGoto = React.useCallback((page: number) => {
    setPage(page);
  }, []);

  const handleChangePageSize = React.useCallback((num: number) => {
    setPerPage(num);
  }, []);

  function renderRows() {
    let _rows;
    const globalFilter = _globalFilterRef.current || state.globalFilter;

    if (!rowSet?.length && !isLoading) {
      _rows = globalFilter ? (
        <td colSpan={visibleColumns?.length} className={bem('empty-filter')} key="empty-filter">
          {customEmptyFilterView ? (
            customEmptyFilterView(tableProps, globalFilter)
          ) : (
            <span>No results for &quot;{globalFilter}&quot;</span>
          )}
        </td>
      ) : (
        <td colSpan={visibleColumns?.length} className={bem('empty')} key="empty">
          {customEmptyView ? customEmptyView(tableProps) : <span>No items</span>}
        </td>
      );

      if (fetchError) {
        _rows = (
          <td colSpan={visibleColumns?.length} className={bem('fetch-error')} key="empty">
            {fetchError}
          </td>
        );
      }

      return (
        <tbody className={bem('tbody')} {...getTableBodyProps()} style={!hasCustomScrollbars ? bodyStyle : null}>
          <tr>{_rows}</tr>
        </tbody>
      );

      // return _rows;
    } else {
      const arr = !data ? Array.from(new Array(_perPage)) : rowSet;
      _rows = arr.map((_v, rowIndex) => {
        const row = rowSet[rowIndex];

        if (!row) {
          return (
            <tr className={bem('tr', ['hidden'])} key={`${rowIndex}-hidden`}>
              <td className={bem('td', ['hidden'])}>&nbsp;</td>
            </tr>
          );
        }

        prepareRow(row);
        return (
          <React.Fragment key={`${rowIndex}`}>
            <tr
              className={bem('tr')}
              key={`${rowIndex}`}
              {...row.getRowProps()}
              onClick={() => onRowClick?.(row, rowIndex)}
            >
              {row.cells.map((cell: Cell<T>, cellIndex: number) => {
                const colProps = cell.getCellProps();
                const column = columns[cellIndex];
                const colClasses = bem('td', [
                  column?.accessor?.toString() || column?.id?.toString(),
                  column?.variant,
                  { center: column?.center },
                  { 'no-pad': column?.noPad },
                ]);
                if (colProps?.style) delete colProps.style.zIndex;
                const colStyle = {
                  ...column?.style,
                  ...colProps?.style,
                };
                if (column?.minWidth) {
                  colStyle.minWidth = column.minWidth;
                }
                return (
                  <td
                    {...colProps}
                    style={colStyle}
                    className={colClasses}
                    key={`td-${rowIndex}-${cellIndex}`}
                    // width={column?.width}
                  >
                    <div className={bem('td-inner')}>{cell.render('Cell')}</div>
                  </td>
                );
              })}
            </tr>
            {hasExpandableRows && (
              <AnimatePresence key={`${rowIndex}-expandable`}>
                {row.isExpanded && (
                  <tr key={rowIndex + '-expandable'}>
                    <td className={bem('td-expandable')} colSpan={visibleColumns?.length}>
                      <AnimateHeight variants={variants} isVisible={row.isExpanded}>
                        {renderRowSubComponent?.({ row })}
                      </AnimateHeight>
                    </td>
                  </tr>
                )}
              </AnimatePresence>
            )}
          </React.Fragment>
        );
      });
    }

    return (
      <tbody className={bem('tbody')} {...getTableBodyProps()} style={!hasCustomScrollbars ? bodyStyle : null}>
        {rowSelectToolbar && (
          <tr className={bem('row-select-toolbar-top')}>
            <td>{rowSelectToolbar(tableProps)}</td>
          </tr>
        )}
        {_rows}
        {isLoading && (
          <tr key="loader" className={bem('loader')}>
            <td>
              <LoadingSpinner />
            </td>
          </tr>
        )}
        {rowSelectToolbar && (
          <tr className={bem('row-select-toolbar-bottom')}>
            <td>{rowSelectToolbar(tableProps)}</td>
          </tr>
        )}
      </tbody>
    );
  }

  const body = renderRows();

  if (!tableOptions) return null;

  const header = (
    <div className={bem('header')}>
      {!hideGlobalFilter && (
        <div className={bem('global-filter')}>
          <div className={bem('global-filter-container')}>
            <div className={bem('custom')}>{customToolbar && customToolbar(tableProps, _searchProps)}</div>
            <div className={bem('global-filter-inner')}>
              {globalFilterBefore}
              <GlobalFilter
                collapse={globalFilterCollapse}
                placeholder={globalFilterPlaceholder}
                globalFilter={fetch ? _globalFilter : state.globalFilter}
                setGlobalFilter={(v: string) => {
                  _globalFilterRef.current = v;
                  _setGlobalFilter(v);
                  setGlobalFilter?.(v);
                  setPage(0);
                }}
              />
              {globalFilterAfter}
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const headerPortalEl = headerPortal?.();

  return (
    <div
      {...otherProps}
      className={bem(
        null,
        [
          { scrollable },
          { 'hor-scroll': horScroll },
          { 'full-width': fullWidth },
          { 'full-height': fullHeight },
          { [variant]: variant },
          { [`size-${size}`]: size },
          { sticky: hasStickyHeader },
          { truncate },
          { 'row-click': hasRowClick },
          { 'sticky-header': hasStickyHeader },
          { 'flex-layout': hasFlexLayout },
          { 'auto-layout': !hasFixedLayout && !hasBlockLayout && !hasAbsoluteLayout && !hasFlexLayout },
          { 'fixed-layout': hasFixedLayout },
          { 'custom-scrollbars': hasCustomScrollbars },
          { empty: !rowSet?.length && !isLoading && !fetchError },
        ],
        [className]
      )}
    >
      {!headerPortalEl && header}
      {customHeader && <div className={bem('custom-header')}>{customHeader(tableProps)}</div>}
      <div className={bem('table-wrapper')}>
        <table {...getTableProps()} className={bem('table', [{ 'use-page-height': usePaginationHeight }])}>
          {caption && <caption>{caption}</caption>}
          {headerGroups && !hideHeaders && (
            <thead className={bem('thead')}>
              {headerGroups.map((headerGroup, index: number) => {
                const rowProps = headerGroup.getHeaderGroupProps();
                return (
                  <tr className={bem('tr')} {...rowProps} key={`tr-${index}`}>
                    {/* FIXME: headerGroup.headers typings does not match documentation at https://react-table.tanstack.com/docs/api/useTable#headergroup-properties */}
                    {
                      //eslint-disable-next-line
                      (headerGroup.headers as any as ColumnWithAllPlugins<T>[]).map((column, colIndex: number) => {
                        const colProps = column.getHeaderProps(
                          hasSortBy && !column.disableSortBy ? column.getSortByToggleProps?.() : undefined
                        );
                        const colClasses = bem('th', [
                          column?.id,
                          column?.variant,
                          { unsorted: !column.isSorted || column.sortedIndex === -1 },
                        ]);

                        const colStyle = {
                          width: column.width,
                          ...column?.style,
                          ...colProps?.style,
                        };
                        return (
                          <th
                            {...colProps}
                            style={colStyle}
                            key={`th-${index}-${colIndex}`}
                            className={colClasses}
                            // @ts-ignore
                            width={column.width}
                          >
                            <div className={bem('th-inner')}>
                              <div className={bem('th-label')}>{column.render('Header')}</div>

                              {hasFilters && (
                                <div
                                  className={bem('th-filter')}
                                  onClick={ev => {
                                    ev.stopPropagation();
                                  }}
                                >
                                  {column.canFilter && column.Filter ? column.render('Filter') : null}
                                </div>
                              )}

                              {hasSortBy && column.canSort && !column.disableSortBy && (
                                <div
                                  className={bem('th-sort', [
                                    // { unsorted: !column.isSorted || column.sortedIndex === -1 },
                                  ])}
                                >
                                  {column.isSorted && column.sortedIndex !== -1 ? (
                                    column.isSortedDesc ? (
                                      <SvgIcon Icon={SortAscendingIcon} />
                                    ) : (
                                      <SvgIcon Icon={SortDescendingIcon} />
                                    )
                                  ) : (
                                    <SvgIcon className={bem('th-unsorted')} Icon={SortAscendingIcon} />
                                  )}
                                </div>
                              )}
                            </div>
                          </th>
                        );
                      })
                    }
                  </tr>
                );
              })}
            </thead>
          )}

          {hasCustomScrollbars && <Scrollbars style={bodyStyle}>{body}</Scrollbars>}
          {!hasCustomScrollbars && body}
        </table>
      </div>
      <div className={bem('footer')}>
        {(hasPagination || hasRemotePagination) && (pages > 1 || pageOptions?.length > 1) && (
          <>
            {hasPagination && (
              <TablePagination<T> tableProps={tableProps} customDetailsDisplay={customDetailsDisplay} />
            )}
            {hasRemotePagination && (
              <TableRemotePagination<T>
                pages={pages}
                page={page}
                total={total}
                perPage={_perPage}
                customDetailsDisplay={customDetailsDisplay}
                onNext={handleNext}
                onPrev={handlePrev}
                onGoto={handleGoto}
                onChangePageSize={handleChangePageSize}
              />
            )}
          </>
        )}
      </div>

      {customFooter && <div className={bem('custom-footer')}>{customFooter(tableProps)}</div>}

      {headerPortalEl && renderPortal(header, () => headerPortalEl)}
    </div>
  );
}

export const Table = React.forwardRef(TableComp);
