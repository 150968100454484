import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useSupporters } from '@gik/api/crm/supporters';
import { useInkind } from '@gik/api/inkinds/inkind';
import { patchInkind } from '@gik/api/inkinds/inkinds';
import { useProducts } from '@gik/checkout/api';
import { CheckoutLauncher } from '@gik/checkout/components/CheckoutLauncher/CheckoutLauncher';
import { openPremiumPageThankYouModal } from '@gik/checkout/components/PremiumPageUpgradeThankYou/PremiumPageUpgradeThankYou';
import { createCartItemFromProduct } from '@gik/checkout/utils/productUtils';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type { InkindPageAPIModel, PatchInkindPageRequest } from '@gik/core/models/gik/InkindPage';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import { getAssetsUrl } from '@gik/core/utils/LinkUtils';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import SideFoliageMobile from '@gik/crm/assets/sideFoliageR2-mobile.svg';
import SideFoliage from '@gik/crm/assets/sideFoliageR2.svg';
import { openInkindPageSettingsModal } from '@gik/inkind-page/components/InkindPageSettingsModal/InkindPageSettingsModal';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { useInkindStore } from '@gik/inkind-page/store/InkindStore';
import { Button } from '@gik/ui/Button';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { ModalButtonFooter } from '@gik/ui/Modal';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import CheckIcon from '@heroicons/react/solid/CheckIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type PremiumPageUpgradeModalInitiatedOn = 'supportersPage' | 'inkindPage' | 'inkindPageSettings' | 'profilePage';
export type PremiumPageUpgradeModalVariant = 'viewSupporters' | 'hideSuggestions' | 'generic' | 'thankYouMessages';

type PatchInkindAfterPremiumUpgrade = Pick<PatchInkindPageRequest, 'enableSuggestedArticles'>;
export interface IPremiumPageUpgradeModalProps {
  initiatedOn: PremiumPageUpgradeModalInitiatedOn;
  variant: PremiumPageUpgradeModalVariant;
  patchInkindRequest?: PatchInkindAfterPremiumUpgrade;
  buttonsPortal?: () => HTMLElement;
  onPurchase?(): void;
  closeModal?(): void;
}

const premiumPageUpgradeProductid = 199038;

export function PremiumPageUpgradeModalContent({
  initiatedOn,
  variant,
  onPurchase,
  patchInkindRequest,
  closeModal,
  buttonsPortal,
}: IPremiumPageUpgradeModalProps) {
  const bem = useBemCN('premium-page-upgrade-content');
  const isMdUp = useBreakpoint(Breakpoint.MD);
  const { t } = useTranslation();

  const [buttonsPortalEl, setButtonsPortalEl] = React.useState<HTMLElement>();

  const inkindRouteId = useInkindStore(state => state.inkindRouteId);
  const { mutate: mutateSupporters } = useSupporters(initiatedOn === 'supportersPage' ? inkindRouteId : null);
  const { data: products } = useProducts({ productIds: [premiumPageUpgradeProductid] });
  const product = products?.[0];
  const cart = product ? [createCartItemFromProduct(product)] : [];
  const { data: inkindPage, mutate: mutateInkindPage } = useInkind(inkindRouteId);

  React.useEffect(() => {
    // trigger a rerender when the button portal resolves to an html element
    if (buttonsPortal) setButtonsPortalEl(buttonsPortal());
  }, [buttonsPortalEl, buttonsPortal]);

  const handleOnCloseSuccessfulPurchase = React.useCallback(() => {
    // Close the premium modal since we have already purchased premium.
    // Since the checkout modal is rendered from this component, the premium
    // page upgrade modal cannot be closed until the checkout modal is closed.
    closeModal?.();
    // launch the page settings modal and scroll to the toggles
    if (variant === 'hideSuggestions' && initiatedOn !== 'inkindPageSettings') {
      // FIXME: back button override issue due to closing modal on same frame
      setTimeout(() => {
        openInkindPageSettingsModal({ inkindRouteId, scrollToSectionId: 'features-section-suggested-items' });
      }, 500);
    }
  }, [closeModal, initiatedOn, inkindRouteId, variant]);

  // close the modal automatically when exiting the supporters page
  React.useEffect(() => {
    function handlePopState() {
      if (window.location.href.indexOf('/supporters') < 0) {
        closeModal?.();
      }
    }
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [closeModal]);

  function parseTitle(variant: PremiumPageUpgradeModalVariant) {
    switch (variant) {
      case 'viewSupporters':
        return t(translationKeys.premiumPageUpgradeModalTitle_Supporters);
      case 'hideSuggestions':
        return t(translationKeys.premiumPageUpgradeModalTitle_Suggestions);
      case 'thankYouMessages':
        return t(translationKeys.premiumPageUpgradeModalTitle_ThankYouMessages);
      default:
        return t(translationKeys.premiumPageUpgradeModalTitle_Generic);
    }
  }

  function parseDescription(variant: PremiumPageUpgradeModalVariant) {
    const descriptionItems = [
      t(
        variant === 'viewSupporters'
          ? translationKeys.premiumPageUpgradeModalSupportersDescriptionAdvanced
          : translationKeys.premiumPageUpgradeModalSupportersDescriptionSimple
      ),
      t(translationKeys.premiumPageUpgradeModalSuggestionsDescription),
      t(translationKeys.premiumPageUpgradeModalFeeDescription),
      t(translationKeys.premiumPageUpgradeModalMissionDescription),
    ];

    // thank you messages description normally comes last except if the modal variant is 'thankYouMessages'
    const thankYouMessages = t(translationKeys.premiumPageUpgradeModalThankYouMessagesDescription);
    variant === 'thankYouMessages'
      ? descriptionItems.unshift(thankYouMessages)
      : descriptionItems.push(thankYouMessages);

    return descriptionItems;
  }

  const title = parseTitle(variant);
  const subtitle = t(translationKeys.premiumPageUpgradeModalSubtitle);
  const descriptionItems = parseDescription(variant);

  if (variant === 'hideSuggestions') {
    // show hide suggestions item first
    [descriptionItems[0], descriptionItems[1]] = [descriptionItems[1], descriptionItems[0]];
  }

  const upgradeButtonText = 'Upgrade your page';
  // product not loaded yet but we want to show the price right away. This must be kept in sync
  const price = product?.price || 10;

  const handleOnCloseCheckout = React.useCallback(() => {
    if (initiatedOn === 'supportersPage') {
      useUserStore.getState().setShouldCloseModalsAfterNavigation(false);
      useUserStore.getState().setShouldUnblurAfterNavigation(false);
    }
    return true;
  }, [initiatedOn]);

  const handleOnSuccessfulPurchase = React.useCallback(
    async (promoCodeId?: string) => {
      Analytics.fireEvent(
        AnalyticsEvents.PremiumPageUpgrade,
        { initiatedOn, variant, promoCodeId },
        () => ['initiatedOn'],
        () => ['variant', 'promoCodeId']
      );

      useInkindStore.getState().setHasPurchased(true);

      // if disabled suggested articles or products, update the page to disable those suggestions
      if (patchInkindRequest) {
        await patchInkind(inkindRouteId, patchInkindRequest);
      }

      // update inkind page to be premium
      const updatedPage: InkindPageAPIModel = {
        ...inkindPage,
        isPremium: true,
        ...patchInkindRequest,
      };

      mutateInkindPage(updatedPage, null, true);

      if (initiatedOn === 'supportersPage') {
        // reload so the PO who purchased will see their purchase history entry
        mutateSupporters();
      }

      // close the modal if a promo code was used to upgrade premium page
      if (promoCodeId) {
        handleOnCloseSuccessfulPurchase();
        UI.notifySuccess('Your promo code has been redeemed and your page has been upgraded!');
        openPremiumPageThankYouModal();
        useInkindStore.getState().setHasPurchased(false);
      }

      onPurchase?.();
    },
    [
      handleOnCloseSuccessfulPurchase,
      initiatedOn,
      inkindPage,
      inkindRouteId,
      mutateInkindPage,
      mutateSupporters,
      onPurchase,
      patchInkindRequest,
      variant,
    ]
  );

  const imageUrl = React.useMemo(() => {
    const suffix = isMdUp ? '' : '-mobile';
    switch (variant) {
      case 'generic':
        return `${getAssetsUrl()}crm/upgrade-generic${suffix}.svg`;
      case 'hideSuggestions':
        return `${getAssetsUrl()}crm/upgrade-suggestions${suffix}.svg`;
      case 'viewSupporters':
        return `${getAssetsUrl()}crm/upgrade-supporters${suffix}.svg`;
      case 'thankYouMessages':
        if (isMdUp) return `${getAssetsUrl()}crm/upgrade-thank-you${suffix}.svg`;
        return `${getAssetsUrl()}crm/upgrade-thank-you${suffix}.png`;
      default:
        return null;
    }
  }, [isMdUp, variant]);

  const buttons = () => {
    return (
      <CheckoutLauncher
        cachedProducts={product ? [product] : undefined}
        cart={cart}
        initialInkindRouteId={inkindRouteId}
        skipProductsCheckoutStep={false}
        onCloseCheckout={handleOnCloseCheckout}
        onSuccessfulPurchase={handleOnSuccessfulPurchase}
        onCloseSuccessfulPurchase={handleOnCloseSuccessfulPurchase}
        navigateToInkindPageAfterPurchase={initiatedOn === 'profilePage'}
        child={(onClick, isLoading) => (
          <Button
            {...bem('upgrade-button')}
            fullWidth
            variant={'premium-unlocked'}
            disabled={isLoading}
            loading={isLoading}
            onClick={onClick}
          >
            {upgradeButtonText}
          </Button>
        )}
        initiatedOn={initiatedOn}
      />
    );
  };

  return (
    <>
      <article {...bem('content')}>
        <aside {...bem('image')}>
          <img alt="Upgrade modal image" src={imageUrl} />
          {/* <ImageSupporters /> */}
        </aside>
        <div {...bem('main', [{ [`variant-${variant}`]: variant }])}>
          <header {...bem('header')}>
            <h1 {...bem('title')}>{title}</h1>
            <p {...bem('subtitle')}>{subtitle}</p>
          </header>
          <section {...bem('body')}>
            <div {...bem('value-container')}>
              <var {...bem('currency')}>$</var>
              <var {...bem('value-integer')}>{price.toFixed(0)}</var>
              <div {...bem('decimals-unit-container')}>
                <var {...bem('value-decimals')}>{String(((price % 1) * 100).toFixed(0)).padStart(2, '0')}</var>
                <span {...bem('per-unit')}>per page</span>
              </div>
            </div>
            <ul {...bem('description')}>
              {descriptionItems.map((item, i) => (
                <li {...bem('description-item')} key={i}>
                  <SvgIcon {...bem('item-icon')} Icon={CheckIcon} />
                  <div {...bem('description-item-text')}>
                    <HTMLParser rawHtml={item} />
                  </div>
                </li>
              ))}
            </ul>
          </section>

          {!isMdUp ? renderPortal(buttons(), buttonsPortal) : buttons()}
        </div>
      </article>
      <div {...bem('foliage')}>
        {/* NOTE: must use plain svg to assure svg blend modes are used which has better browser compatibility */}
        {isMdUp ? <SideFoliage /> : <SideFoliageMobile />}
      </div>
    </>
  );
}

export type openPremiumPageUpgradeModalProps = {
  initiatedOn: PremiumPageUpgradeModalInitiatedOn;
  variant: PremiumPageUpgradeModalVariant;
  // bring the user back to this inkind page when the modal closes
  inkindRouteId?: string;
  onPurchase?: () => void;
  patchInkindRequest?: PatchInkindAfterPremiumUpgrade;
};

export function openPremiumPageUpgradeModal({
  initiatedOn,
  variant,
  inkindRouteId,
  onPurchase,
  patchInkindRequest,
}: openPremiumPageUpgradeModalProps) {
  const bem = bemBlock('premium-page-upgrade-modal');
  // if (inkindRouteId) {
  //   useInkindStore.getState().setInkindRouteId(inkindRouteId);
  // }

  const hasPurchased = useInkindStore.getState().hasPurchased;

  const footerId = 'PremiumPageUpgradeModalFooter';

  Analytics.fireEvent(
    AnalyticsEvents.PremiumPageUpgradeModalOpened,
    { initiatedOn, variant },
    () => ['initiatedOn'],
    () => ['variant']
  );

  return UI.dialog(
    ({ close }) => (
      <PremiumPageUpgradeModalContent
        buttonsPortal={() => document.getElementById(footerId)}
        initiatedOn={initiatedOn}
        variant={variant}
        onPurchase={onPurchase}
        patchInkindRequest={patchInkindRequest}
        closeModal={close}
      />
    ),
    {
      className: bem(null, [{ [`variant-${variant}`]: variant }]),
      closable: true,
      shouldCloseOnOverlayClick: false,
      disableSystemStyling: true,
      autowidth: false,
      useBlur: false,
      footer: <ModalButtonFooter centeredButtons={false} id={footerId} />,

      // NOTE: commented out because it was causing this issue: https://www.notion.so/giveinkind/Upgrade-overlay-remains-when-back-buttons-is-used-rather-than-close-control-4428b2eb926d47c086aad2d40b36bf03
      // backButtonOverrideEnabled: initiatedOn !== 'supportersPage',

      onClose: () => {
        if (initiatedOn === 'supportersPage' && !hasPurchased) {
          setTimeout(() => {
            // bring user back to the inkind page
            history.back();
          }, 1000);
          return;
        }

        return null;
      },
    }
  );
}
