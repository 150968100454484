import { v4 as uuidv4 } from 'uuid';
import type { GetState, SetState } from 'zustand';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface SessionKeyStore {
  browserSessionId: string;
  updateBrowserSessionId: () => void;
}

function createSessionKey(): string {
  return uuidv4();
}

export const useAppSessionStore = create<SessionKeyStore>(
  persist(
    (set: SetState<SessionKeyStore>, get: GetState<SessionKeyStore>) => ({
      browserSessionId: null,
      updateBrowserSessionId: () => set(() => ({ browserSessionId: createSessionKey() })),
    }),
    {
      name: 'app-session-store',
      getStorage: () => sessionStorage,
    }
  )
);

if (useAppSessionStore.getState().browserSessionId === null) {
  useAppSessionStore.getState().updateBrowserSessionId();
}
