import { useUserOwnedOrOrganizingPagesInfinite } from '@gik/api/inkinds/userPages';
import { CheckoutLauncher } from '@gik/checkout/components/CheckoutLauncher/CheckoutLauncher';
import type { CheckoutFormInitiatedOnType } from '@gik/checkout/types';
import { useIsMounted } from '@gik/core/hooks/hooks/useIsMounted';
import type { CartItem } from '@gik/core/models/gik/Order';
import type { Product } from '@gik/core/models/gik/Product';
import { useUserStore } from '@gik/core/store/UserStore';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { getInkindPageViewHistory } from '@gik/inkind-page/components/InkindPageViewRecorder/InkindPageViewRecorder';
import translationKeys from '@gik/shop/i18n/en';
import { Button } from '@gik/ui/Button';
import type { FormRef } from '@gik/ui/Form';
import { AddToWishlistPopover } from '@gik/ui/gik/AddToWishlistPopover';
import { LineClamp } from '@gik/ui/LineClamp';
import { ButtonBone } from '@gik/ui/SkeletonLoader';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { Tooltip } from '@gik/ui/Tooltip';
import { UI } from '@gik/ui/UIManager';
import MinusIcon from '@heroicons/react/solid/MinusIcon';
import PlusIcon from '@heroicons/react/solid/PlusIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProductActionsProps {
  product: Product;
  selectedCartItem?: CartItem;
  showBuyButton?: boolean;
  showWishlistButton?: boolean;
  inkindRouteId?: string;
  cardImage?: string;

  initiatedOn?: CheckoutFormInitiatedOnType;
  onError?(): void;
  formRef: FormRef;
  onPickAlternativeProductVariation?: () => Promise<void>;
}

function ProductActionsComp({
  showBuyButton = true,
  showWishlistButton = true,
  initiatedOn,
  cardImage,
  product,
  inkindRouteId,
  selectedCartItem,
  onError,
  formRef,
  onPickAlternativeProductVariation,
}: IProductActionsProps) {
  //eslint-disable-next-line
  const { t } = useTranslation();
  const [formIsValid, setFormIsValid] = React.useState<boolean>(false);

  React.useEffect(() => {
    let unsubscribe: () => void;

    if (formRef) {
      // setFormIsValid(!!formRef.isValid);
      unsubscribe = formRef.subscribe('isValid', isValid => {
        setFormIsValid(!!isValid);
      });
    }

    return () => {
      unsubscribe?.();
    };
  }, [formRef]);

  const inkindPageViewHistory = getInkindPageViewHistory();
  const isMounted = useIsMounted();

  const [isAdded, setIsAdded] = React.useState<boolean>(false);

  const userStore = useUserStore();
  const { data: inkindPages } = useUserOwnedOrOrganizingPagesInfinite(userStore.id, {
    perPage: 4,
  });

  const hasPhysicalCard = selectedCartItem?.productType === 'physical';

  React.useEffect(() => {
    if (!inkindPages) return;
    // figure out if this product was already added to a wishlist of a page that the user owns
    const isAdded =
      inkindPages?.findIndex(item => {
        return item.wishlistProductIds?.some(id => id === product?.id);
      }) > -1;

    setIsAdded(isAdded);
  }, [inkindPages, product?.id]);

  const bem = useBemCN('product-actions');

  if (!product) return <ProductActionsSkeleton />;

  function renderZipCodes(title: string, zipCodes: number[]) {
    return (
      <div {...bem('zip-codes', undefined, 'gik-article')}>
        <LineClamp fadeOut lines={2} compactButton>
          <span>{title}: </span> {zipCodes.join(', ')}
        </LineClamp>
      </div>
    );
  }

  const isOpenLoop = product.isPerfectgiftProduct && product.isToggleable;

  return (
    <>
      <div {...bem()}>
        {showBuyButton && (
          <CheckoutLauncher
            initiatedOn={initiatedOn}
            skipProductsCheckoutStep
            hideProductsCheckoutStep={initiatedOn === 'productPage'}
            initialInkindRouteId={inkindRouteId}
            inkindPageViewHistory={inkindPageViewHistory}
            cachedProducts={[product]}
            cart={selectedCartItem ? [selectedCartItem] : []}
            hasPhysicalCard={hasPhysicalCard}
            onPickAlternativeVariation={onPickAlternativeProductVariation}
            child={(onClick, isLoading) => (
              <Button
                variant="primary"
                loading={selectedCartItem !== null && isLoading}
                disabled={!formIsValid && product.type !== 'fixed'}
                {...bem('button-buy')}
                onClick={async e => {
                  await formRef.submitForm();

                  if (!formIsValid) {
                    UI.notifyError('Please fix all errors to continue');
                    onError?.();
                  } else {
                    if (!formIsValid && product.type !== 'fixed') return;
                    onClick(e);
                  }
                }}
              >
                {selectedCartItem?.productType === 'physical' ||
                (selectedCartItem?.productType === undefined && isOpenLoop)
                  ? 'Customize & Buy'
                  : t(translationKeys.buyButtonLabel).toString()}
              </Button>
            )}
          />
        )}

        {/* TODO: should be able to wishlist hidden products */}
        {!product.isHiddenProduct && showWishlistButton && (
          <AddToWishlistPopover
            productId={product.id}
            child={(onClick, isLoading) => (
              <Tooltip text={isAdded ? 'Remove from Wishlist' : 'Add To Wishlist'}>
                <div>
                  <Button
                    pill
                    disabled={isLoading}
                    loading={isLoading}
                    variant={'custom'}
                    {...bem(
                      'button-wishlist',
                      showBuyButton ? 'buy-button-visible' : null,
                      initiatedOn === 'wishlist' && !showBuyButton && selectedCartItem.productType === 'physical'
                        ? 'tw-mt-4'
                        : ''
                    )}
                    onClick={onClick}
                    prepend={
                      isMounted() && inkindPages ? (
                        isAdded ? (
                          <SvgIcon Icon={MinusIcon} />
                        ) : (
                          <SvgIcon Icon={PlusIcon} />
                        )
                      ) : null
                    }
                  >
                    {t(translationKeys.wishlistButtonLabel).toString()}
                  </Button>
                </div>
              </Tooltip>
            )}
          />
        )}
      </div>
      {product.eligibleZipCodes?.length
        ? renderZipCodes(t(translationKeys.eligibleZipCodes), product.eligibleZipCodes)
        : product.ineligibleZipCodes?.length
        ? renderZipCodes(t(translationKeys.ineligibleZipCodes), product.ineligibleZipCodes)
        : null}
    </>
  );
}

export const ProductActions = withComponentErrorBoundary(ProductActionsComp);

export function ProductActionsSkeleton() {
  const bem = useBemCN('product-actions-skeleton');
  return (
    <div {...bem()}>
      <ButtonBone />
      <ButtonBone circle />
    </div>
  );
}
