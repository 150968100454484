import TranslationUtils from '@gik/core/utils/TranslationUtils';

const blockName = 'gift-card-item';

const translations = {
  giftedAmmount: 'Gifted Amount:',
  from: 'From:',
  btnRedeem: 'Redeem',
  btnPending: 'Pending',
  linkedStatus: 'Linked',
  usedStatus: 'Used',
  eCodeStatus: 'eCode',
  onlineStatus: 'Online',
  mailedStatus: 'Mailed',
};

export default {
  [blockName]: translations,
};

export const translationKeys: typeof translations = TranslationUtils.mapKeys(blockName, translations);
