import ImgAmex from '@gik/core/assets/img/creditcard/amex.svg';
import ImgDiners from '@gik/core/assets/img/creditcard/diners.svg';
import ImgDiscover from '@gik/core/assets/img/creditcard/discover.svg';
import ImgJCB from '@gik/core/assets/img/creditcard/jcb.svg';
import ImgMasterCard from '@gik/core/assets/img/creditcard/mastercard.svg';
import ImgVisa from '@gik/core/assets/img/creditcard/visa.svg';
import { gikClassPrefix } from '@gik/core/constants';
import React from 'react';

const blockName = `${gikClassPrefix}-creditcards`;

type CreditCardType = 'visa' | 'amex' | 'mastercard' | 'discover' | 'jcb' | 'diners';

export interface BillingFormProps {
  cards?: CreditCardType[];
}

export default function CreditCardImages({
  cards = ['visa', 'amex', 'mastercard', 'discover', 'jcb', 'diners'],
}: BillingFormProps): React.ReactElement {
  return (
    <div className={blockName}>
      {cards.map(card => {
        switch (card) {
          case 'visa':
            return <ImgVisa key={card} />;
          case 'amex':
            return <ImgAmex key={card} />;
          case 'mastercard':
            return <ImgMasterCard key={card} />;
          case 'discover':
            return <ImgDiscover key={card} />;
          case 'jcb':
            return <ImgJCB key={card} />;
          case 'diners':
            return <ImgDiners key={card} />;
        }
      })}
    </div>
  );
}
