// For the search only version
import type { Hit, SearchResponse } from '@algolia/client-search';
import { logger } from '@gik/analytics/utils/logger';
import { swrWithSerializableObjectKeys } from '@gik/core/api/swr/middlewares/swrWithSerializableObjectKeys';
import { swrDefaults } from '@gik/core/api/swr/swrDefaults';
import { inkindsIndex, intercomArticlesIndex, postsIndex, productsIndex } from '@gik/search/algolia';
import type { SearchIndex } from 'algoliasearch/lite';
import React, { useEffect } from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

export type SearchType = 'inkinds' | 'products' | 'posts' | 'helpArticles';

export type AlgoliaObject = {
  url: string;
  objectID: string;
};
export type InkindAlgoliaObject = AlgoliaObject & {
  pageTitle: string;
  slug: string;
  pageDescription: string;
  recipientCity: string;
  recipientState: string;
  smallImageUrl: string;
  mediumImageUrl: string;
  ownerEmail: string;
  ownerFullName: string;
  recipientFullName: string;
  recipientZipCode: string;
  ownerId: string;
  organizersIds: string[];
  followersIds: string[];
  groupIds: string[];
  isPremium: boolean;
  shortUrl: string;
};

type WordPressAlgoliaObject = {
  slug: string;
  title: string;
  content: string;
  'img-thumbnail': string;
  'img-api_thumb_square_lg': string;
  'img-acf-thumbnail': string;
};

export type PostAlgoliaObject = AlgoliaObject &
  WordPressAlgoliaObject & {
    preview_mode: boolean;
  };
export type ProductAlgoliaObject = AlgoliaObject &
  WordPressAlgoliaObject & {
    gridImage: string;
    'img-offsite_image_small': string;
    'img-offsite_image_large': string;
    tc_istcproduct: string;
    pg_ispgproduct: string;
    content_tag: string[];
    'gift-card-category': string[];
    'gift-card-category-ids': number[];
    product_cat: string[];
    product_tag: string[];
    recipient: string[];
    situation: string[];
    source: string[];
    'wishlist-situation-bundle': string[];
    zipcodes_elegible: string[];
  };

type Filter<K, V> =
  | Array<Filter<K, V>>
  | {
      key: K;
      value: V;
    };

export type IntercomArticleAlgoliaObject = AlgoliaObject & {
  title: string;
  description: string;
};

export type InkindSearchFilter = Filter<
  'situations' | 'recipientState' | 'groupIds' | 'hiddenFromSearch' | 'ownerId' | 'organizersIds' | 'followersIds',
  string | boolean | number
>;
export type PostSearchFilter =
  | Filter<'situation' | 'article-type' | 'content_tag', string>
  | Filter<'preview_mode', boolean>;
export type ProductSearchFilter = Filter<
  'situation' | 'recipient' | 'product_cat' | 'gift-card-category-ids' | 'pg_ispgproduct' | 'tc_istcproduct',
  string | number
>;

type SearchParams = {
  query: string;
  searchType: SearchType;
  filters?: InkindSearchFilter[] | PostSearchFilter[] | ProductSearchFilter[];
  perPage: number;
  page: number;
  minQueryCharacters: number;
  cacheable?: boolean;
};

export function useSearch<T = InkindAlgoliaObject>(
  query: string,
  searchType: SearchType,
  filters?: InkindSearchFilter[] | PostSearchFilter[] | ProductSearchFilter[],
  perPage = 10,
  page = 0,
  minQueryCharacters = 1
) {
  const [searchResults, setSearchResults] = React.useState<SearchResponse<T> & { searchType: SearchType }>();
  const index = React.useMemo(() => getIndex(searchType), [searchType]);

  useEffect(() => {
    search<T>({ query, searchType, filters, perPage, page, minQueryCharacters }, index).then(results => {
      setSearchResults({ ...results, searchType });
    });
  }, [query, index, perPage, page, filters, minQueryCharacters, searchType]);

  return searchResults;
}

function search<T = InkindAlgoliaObject>(
  { query, searchType, filters, perPage, page, minQueryCharacters, cacheable = true }: SearchParams,
  index: SearchIndex
) {
  return new Promise<SearchResponse<T>>(resolve => {
    if ((query?.length || 0) >= minQueryCharacters) {
      index
        ?.search<T>(query ?? '', {
          hitsPerPage: perPage,
          page,
          facetFilters: filters?.map(filter => filterMap(filter)),
          cacheable,
        })
        .then(resolve, logger.error);
    }
  });
}

function getIndex(searchType: SearchType): SearchIndex {
  switch (searchType) {
    case 'inkinds':
      return inkindsIndex;
    case 'products':
      return productsIndex;
    case 'posts':
      return postsIndex;
    case 'helpArticles':
      return intercomArticlesIndex;
    default:
      throw new Error('Unknown search type: ' + searchType);
  }
}

function filterMap<K, V>(filter: Filter<K, V>) {
  if (Array.isArray(filter)) {
    return filter.map(filterMap).flat();
  }
  return `${filter.key}:${filter.value}`;
}

async function algoliaFetcher<T = InkindAlgoliaObject>(index: SearchType, searchParamsJSON: string) {
  const searchParams: SearchParams = JSON.parse(searchParamsJSON);
  const response = await search<T>({ ...searchParams, cacheable: false }, getIndex(index));
  if (response) {
    return response.hits;
  }
}

export function useSWRSearch<T = InkindAlgoliaObject>(searchParams?: SearchParams, config?: SWRConfiguration) {
  const conf: SWRConfiguration = {
    ...swrDefaults,
    ...config,
    use: [swrWithSerializableObjectKeys],
  };

  return useSWR<Hit<T>[]>(
    searchParams
      ? [
          searchParams.searchType,
          JSON.stringify({
            ...searchParams,
            searchType: undefined,
          }),
        ]
      : null,
    algoliaFetcher,
    conf
  );
}
