import { useBemCN } from '@gik/core/utils/bemBlock';
import type { UIVariant } from '@gik/ui/types';
import React from 'react';

export interface ILoadingSpinnerProps {
  variant?: UIVariant;
  className?: string;
  label?: string;
  center?: boolean;
}

export function LoadingSpinner({ center, className, label, variant = 'primary', ...otherProps }: ILoadingSpinnerProps) {
  const bem = useBemCN('loading-spinner');
  return (
    <div {...otherProps} {...bem(null, [{ center }, { [variant]: true }], [className])}>
      <div className="sk-bounce">
        <div className="sk-bounce-dot" />
        <div className="sk-bounce-dot" />
      </div>
      {label}
    </div>
  );
}
