import { isHomepage, isResetPasswordPage } from '@/utils/environment';
import { navigateTo } from '@/utils/navigateTo';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { oauthLogin, userSignUp } from '@gik/api/users/auth';
import { userLogout } from '@gik/api/users/logout';
import type { IOAuthUserLoginResponse } from '@gik/api/users/types';
import type { IForgotPasswordFormValues } from '@gik/auth/components/ForgotPassword/ForgotPasswordForm';
import type { ISignInFlowProps } from '@gik/auth/components/SignInFlow/SignInFlow';
import type { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';
import { LoginProvider } from '@gik/auth/utils/LoginProvider';
import type UserRegistrationData from '@gik/core/models/gik/auth/UserRegistrationData';
import routes from '@gik/core/routes';
import { useUserStore } from '@gik/core/store/UserStore';
import { isInApp } from '@gik/core/utils/browser';
import { storage } from '@gik/core/utils/Storage';
import { UI } from '@gik/ui/UIManager';

export type AuthModalSettings = Omit<
  ISignInFlowProps,
  'onSuccess' | 'onForgotPassword' | 'id' | 'className' | 'style'
> & {
  onCancel?: () => void;
  noRedirect?: boolean;
};

/**
 * Most high level function to perform a sign in sequence.
 *
 * Whether this is a opening a modal or navigating to a specific route, this function should determine the behaviour.
 *
 * @param settings Optional parameter containing settings for error message shown to user on a failed oauth login (e.g.
 * incorrect provider), and what action to take when the flow is explicitly cancelled by the user
 */
export async function signin(settings?: AuthModalSettings) {
  const { openSignInModal } = await import('@gik/auth/utils/authModals');
  const response = await openSignInModal(settings);

  if (response) {
    performLogin(
      response.userId,
      response.refreshToken,
      response.refreshTokenHash,
      response.redirectUrl,
      settings?.noRedirect
    );
    return response.userId;
  }
  return response;
}

/**
 * Low level login sequence
 *
 * @param userId The id of the user to be logged in
 */
export function performLogin(
  userId: string,
  refreshToken: string,
  refreshTokenHash: string,
  redirectUrl?: string,
  noRedirect?: boolean
) {
  useUserStore.getState().login(refreshToken, refreshTokenHash);

  if (noRedirect) return;

  checkRedirectLoggedInUserToUserProfile(userId, redirectUrl);
}

export function checkRedirectLoggedInUserToUserProfile(userId: string, redirectUrl?: string) {
  const doRedirect = isHomepage() || isResetPasswordPage() || isInApp();

  if (doRedirect && userId) {
    (async () => {
      const { navigateTo } = await import('@/utils/navigateTo');

      if (redirectUrl && (!isInApp() || (isInApp() && !isHomepage(redirectUrl) && !isResetPasswordPage(redirectUrl)))) {
        navigateTo(redirectUrl);
      } else {
        navigateTo(`/user/profile`);
      }
    })();
  }
}

/**
 * Most high level function to perform a reset password sequence.
 *
 * Whether this is a opening a modal or navigating to a specific route, this function should determine the behaviour.
 */
export async function resetPassword(initialValues?: Partial<IForgotPasswordFormValues>) {
  const { openForgotPasswordModal } = await import('@gik/auth/utils/authModals');
  await openForgotPasswordModal(initialValues);
}

export async function executeOauthLogin(
  provider: ExternalLoginProvider,
  accessToken: string,
  inkindRouteId: string,
  subscribeToNewsletter: boolean
): Promise<IOAuthUserLoginResponse> {
  const result = await oauthLogin(provider, accessToken, inkindRouteId, subscribeToNewsletter);

  if (result.isNewAccount) {
    finishSignup(result.userId, provider);
  }

  return result;
}

export async function executeUserSignUp(args: UserRegistrationData) {
  const result = await userSignUp(args);

  if (result.userId) {
    finishSignup(result.userId);
  }

  return result;
}

function finishSignup(userId: string, provider?: LoginProvider) {
  // fire legacy event
  Analytics.fireEvent(AnalyticsEvents.User_Registered_GTM, { userId, provider: provider || LoginProvider.EMAIL });
}

/**
 * Most high level function to perform a logout sequence.
 *
 * Whether this is a opening a modal or navigating to a specific route, this function should determine the behaviour.
 */
export async function logout(goToHome = true) {
  await userLogout(useUserStore.getState().refreshToken);
  // log user out from the userStore
  useUserStore.getState().logout();

  storage.remove('stripe-token');
  storage.remove('stripe-token-last-4-digits');

  if (goToHome) {
    navigateTo(routes.home);
  }
}

/**
 * Most high level function to determine and perform a switch account sequence.
 *
 * Whether this is a opening a modal or navigating to a specific route, this function should determine the behaviour.
 */
export async function switchUserAccount() {
  // TODO: implement a switch user account modal
  // at the moment just show a confirmation modal and ask the user to logout and switch accounts.

  const response = await UI.confirm(
    'You are currently logged in with the incorrect account to be able to send this Thank You message. Would you like to sign in with a different account to proceed?'
  );

  if (!response) {
    // user cancelled just resolve this promise
    return Promise.resolve();
  }

  storage.remove('stripe-token');
  storage.remove('stripe-token-last-4-digits');

  // user clicked ok to switch accounts
  // logout without redirecting to home
  // await logout(false);
  // show login and return it's promise
  const loginResponse = await signin();
  return loginResponse;
}
