import { Breakpoint, useBreakpoint } from '@gik/core/hooks/CustomHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import React from 'react';
import { HorizontalScroll } from '../HorizontalScroll';

export interface ITabbedNavItem {
  id: number;
  title: React.ReactNode;
  slug?: string;
  className?: string;
}

export interface ITabbedNavProps<T = ITabbedNavItem> extends Omit<React.HTMLAttributes<HTMLUListElement>, 'onChange'> {
  items?: T[];
  value?: number; // selected index
  arrows?: boolean;
  fadeSides?: boolean;
  renderItem?: (item: T, index: number) => React.ReactElement;
  onChange?: (item: T, index: number) => void;
}

export function TabbedNav<T extends ITabbedNavItem>({
  items,
  value,
  className,
  arrows,
  fadeSides,
  onChange,
  renderItem,
  ...otherProps
}: ITabbedNavProps<T>): React.ReactElement {
  const bem = bemBlock('tabbed-nav');
  const isSmUp = useBreakpoint(Breakpoint.SM);

  return (
    <div className={bem(null, null, className)}>
      <HorizontalScroll fadeSides={fadeSides} arrows={arrows} scrollbar={!isSmUp}>
        <ul className={bem('list')} {...otherProps}>
          {items?.map((item, i) => (
            <li
              className={bem(
                'item',
                [
                  {
                    selected: value === item.id,
                  },
                ],
                item.className
              )}
              onClick={() => onChange(item, i)}
              key={i}
            >
              {renderItem ? renderItem(item, i) : item.title}
            </li>
          ))}
        </ul>
      </HorizontalScroll>
    </div>
  );
}
