import { gikClassPrefix } from '@gik/core/constants';
import classnames from 'classnames';
import React from 'react';
import type { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import NumberFormat from 'react-number-format';
import type { InputProps } from '../Input/Input';
import { Input, inputBlockName } from '../Input/Input';

// TODO: this should be moved somewhere else and more currencies should be added
// or this can come from a database
export const currencySigns = {
  USD: '$',
  EUR: '€',
  THB: '฿',
};

export interface InputCurrencyProps extends InputProps {
  /**
   * Currency code
   */
  code?: string;

  /**
   * Use the currency's sign as a prefix or suffix (use "none" to disable)
   */
  sign?: 'prefix' | 'suffix' | 'none' | string;

  /**
   * Separator to add between the sign and the value
   */
  signSeparator?: string;

  formatProps?: NumberFormatProps;

  onFormattedValueChange?(value: NumberFormatValues): void;
  onValueBlur?(value: string): void;
}

// block name for this component
const blockName = `${gikClassPrefix}-input-currency`;

/**
 * GIK Input component for currencies
 */
export function InputCurrency({
  code = 'USD',
  signSeparator = ' ',
  sign = 'prefix',
  className,
  onValueChange,
  onFormattedValueChange,
  onBlur,
  onValueBlur,
  formatProps = {},
  ...otherProps
}: InputCurrencyProps): React.ReactElement {
  const blockClasses = classnames([blockName, className || '']);
  const [value, setValue] = React.useState<string>((otherProps.value || otherProps.defaultValue || '').toString());

  const _sign = currencySigns[code];

  // use thousands separator by default
  if (formatProps.thousandSeparator === undefined) formatProps.thousandSeparator = true;
  // also pass the placeholder to the NumberFormat component
  formatProps.placeholder = otherProps.placeholder;
  formatProps.disabled = otherProps.disabled;

  if (sign === 'prefix') {
    formatProps.prefix = _sign + signSeparator;
  } else if (sign === 'suffix') {
    formatProps.suffix = signSeparator + _sign;
  }

  function handleValueChange(values: NumberFormatValues): void {
    setValue(values.value);
    if (onFormattedValueChange) onFormattedValueChange(values);
    if (onValueChange) onValueChange(values.value);
  }

  function handleBlur(ev: React.FocusEvent<HTMLInputElement>) {
    if (onValueBlur) onValueBlur(value);
    if (onBlur) onBlur(ev);
  }

  delete otherProps.onChange;

  return (
    <Input
      className={blockClasses}
      onBlur={handleBlur}
      customInput={customProps => (
        <NumberFormat
          value={value}
          className={`${inputBlockName}__input`}
          onValueChange={handleValueChange}
          onFocus={customProps.onFocus}
          onBlur={customProps.onBlur}
          {...formatProps}
        />
      )}
      {...otherProps}
    />
  );
}
