import Link from '@/components/Link';
import bemBlock from '@gik/core/utils/bemBlock';
import { isInApp } from '@gik/core/utils/browser';
import React from 'react';

// This comes from link.d.ts in NextJs, TODO: re-include after migration
type LinkProps = {
  href: string;
  as?: string;
  replace?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  passHref?: boolean;
  prefetch?: boolean;
  locale?: string | false;
};

export type ExternalLinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'rel' | 'target'> &
  Pick<LinkProps, 'href'>;

const blockName = 'external-link';

// TODO: add support for a delay
function ExternalLinkComp({ children, className, href, ...otherProps }: ExternalLinkProps, ref) {
  const bem = bemBlock(blockName);

  return (
    <Link
      href={href}
      ref={ref}
      target={isInApp() ? null : '_blank'}
      rel="noreferrer"
      className={bem(null, null, [className])}
      {...otherProps}
    >
      {children}
    </Link>
  );
}

export const ExternalLink = React.forwardRef(ExternalLinkComp);
