import { useEnvStore } from '@gik/core/store/EnvStore';
import type { ISearchDrawerProps } from '@gik/search/components/SearchDrawer';
import { useUserPages } from '@gik/user-profile/hooks/useUserPages';
import dynamic from 'next/dynamic';
import React from 'react';

const SearchDrawer = dynamic<ISearchDrawerProps>(
  () => import('@gik/search/components/SearchDrawer').then(mod => mod.SearchDrawer),
  {
    //ssr: false, // causes frontend to sometimes crash with "Invalid or unexpected token"
  }
);

const Notifications = dynamic(() => import('@gik/ui/Notifications').then(mod => mod.Notifications), {
  ssr: false,
});

const Dialogs = dynamic(() => import('@gik/ui/Dialogs').then(mod => mod.Dialogs), {
  ssr: false,
});

export function UIWrapper(): React.ReactElement {
  const admin = useEnvStore(state => state.ADMIN);
  useUserPages();

  return (
    <>
      <Notifications />
      <Dialogs />
      {admin !== 'true' && <SearchDrawer />}
    </>
  );
}
