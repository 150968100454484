import type { IWordpressGiftCardCategory } from '@gik/calendar/models/GiftCardCategory';
import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { wpApi } from '@gik/core/api/ky/wpApi';
import { useWPApi } from '@gik/core/api/swr/useWPApi';

const path = 'gift-card-category';

function getParams() {
  return {
    _fields: ['id', 'name', 'acf.sort_order'],
  };
}

export function getGiftcardCategories() {
  return wpApi
    .get(path, {
      searchParams: getParams(),
    })
    .json<IWordpressGiftCardCategory[]>();
}

export function useGiftcardCategories(config?: SWRConfigInterface) {
  return useWPApi<IWordpressGiftCardCategory[]>(path, getParams(), config);
}
