import type { MatchConditions } from '@casl/ability';
import { PureAbility, subject as applySubject } from '@casl/ability';
import type { AbilityTupleType } from '@casl/ability/dist/types/types';
import type { UserRawRule } from './UserStore';
import { useUserStore } from './UserStore';

export type AdminPermissionAction = 'create' | 'read' | 'update' | 'delete' | 'manage' | 'impersonate' | 'preview';
export type AdminPermissionSubject =
  | 'dashboard'
  | 'users'
  | 'groups'
  | 'inkinds'
  | 'products'
  | 'roles'
  | 'articles'
  | 'cache'
  | 'systemToggles'
  | 'devtools';

export type AdminPermissionCriteriaType = {
  [key: string]: unknown;
};

export type UserPermissionAction = 'create' | 'manage' | 'preview';
export type UserPermissionSubject = 'blog' | 'inkinds' | 'groups';

export type UserPermissionCriteriaType = {
  groupId: string;
};

export type GroupRole = {
  id: string;
  name: string;
  groupId?: string;
};

type CanConditions = {
  groupId?: string;
  inkindRouteId?: string;
};

/**
 * Determines whether the logged in user can do the requested action
 * @param action the action the user wants to take
 * @param subject on what entity or feature
 * @param groupId Restrict ability to user who has access to the action for the given group
 * @param permissions optional permissions from useUserStore, will be retrieved using the getState function if not provided
 * @returns true if the feature can be accessed
 */
export function can(
  action: UserPermissionAction,
  subject: UserPermissionSubject,
  conditions?: CanConditions,
  permissions?: UserRawRule[]
) {
  const ability = new PureAbility<
    AbilityTupleType<UserPermissionAction, UserPermissionSubject>,
    MatchConditions<UserPermissionCriteriaType>
  >(permissions || useUserStore.getState().permissions, {
    conditionsMatcher:
      conditions?.groupId || conditions?.inkindRouteId
        ? _matchConditions => (object: { groupId: string; inkindRouteId: string }) => {
            // FIXME: typings
            const matchConditions = _matchConditions as unknown as { groupIds: string[]; inkindRouteIds: string[] };
            return (
              (object.groupId && matchConditions.groupIds?.includes(object.groupId)) ||
              (object.inkindRouteId && matchConditions.inkindRouteIds?.includes(object.inkindRouteId))
            );
          }
        : _ => () => true,
  });

  // FIXME: typings
  const appliedSubject = applySubject(subject, conditions) as unknown as UserPermissionSubject;
  return ability.can(action, appliedSubject ?? subject);
}

export function useCan(action: UserPermissionAction, subject: UserPermissionSubject, conditions?: CanConditions) {
  const permissions = useUserStore(state => state.permissions);
  return can(action, subject, conditions, permissions);
}

export function useInkindCan(action: UserPermissionAction, inkindRouteId: string, groupId: string | undefined) {
  return useCan(action, 'inkinds', { inkindRouteId, groupId });
}

/**
 * Determines whether the logged in user can do the requested action
 * @param action the action the user wants to take
 * @param subject on what entity or feature
 * @param groupId Restrict ability to user who has access to the action for the given group
 * @returns true if the feature can be accessed
 */
export function adminCan(action: AdminPermissionAction, subject: AdminPermissionSubject, groupId?: string) {
  const adminPermissions = useUserStore.getState().adminPermissions;
  const ability = new PureAbility<
    AbilityTupleType<AdminPermissionAction, AdminPermissionSubject>,
    MatchConditions<AdminPermissionCriteriaType>
  >(adminPermissions, {
    conditionsMatcher: groupId
      ? conditions => (object: { groupId: string }) => {
          // FIXME: typings
          const groupConditions = conditions as unknown as { groupIds: string[] };
          return groupConditions.groupIds?.includes(object.groupId);
        }
      : _ => () => true,
  });

  return ability.can(action, subject);
}

export function useAdminCan(action: AdminPermissionAction, subject: AdminPermissionSubject, groupId?: string) {
  return adminCan(action, subject, groupId);
}
