import { gikClassPrefix } from '@gik/core/constants';
import noop from '@gik/core/utils/noop';
import { HTMLParser } from '@gik/ui/HTMLParser';
import classnames from 'classnames';
import React from 'react';
import type { UISize, UIVariant } from '../types';
import { RadioGroupContext } from './RadioGroup';

export interface RadioProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  /**
   * Style variant to use
   */
  variant?: UIVariant;

  /**
   * Size the component on a fixed scale
   */
  size?: UISize;

  /**
   * Force display of focus state
   */
  focus?: boolean;

  /**
   * Auto focus the field when the component is mounted
   */
  autoFocus?: boolean;

  /**
   * Put component in disabled mode
   */
  disabled?: boolean;

  /**
   * name for the component
   */
  name?: string;

  /**
   * Value for this radio component
   */
  value?: string;

  /**
   * TabIndex that is passed to the main DOM element.
   * Set this to -1 to exclude this element from the index
   */
  tabIndex?: number;

  /**
   * Label to add after the input
   */
  label?: string;

  /**
   * Label to add before the input
   */
  labelBefore?: string;

  /**
   * display the component in an error state
   */
  hasError?: boolean;

  /**
   * display the component in a warning state
   */
  hasWarning?: boolean;

  /**
   * display the component in a success state
   */
  hasSuccess?: boolean;

  onChange?: (value: boolean) => void;
}

// block name for this component
const blockName = `${gikClassPrefix}-radio`;

/**
 * GIK Radio component
 */
function RadioComp(
  {
    className,
    variant = 'default',
    size = 'base',
    tabIndex,
    disabled,
    focus,
    autoFocus,
    name,
    value,
    label,
    labelBefore,
    hasError,
    hasWarning,
    hasSuccess,
    onFocus,
    onBlur,
    onChange,
    ...otherProps
  }: RadioProps,
  ref: React.LegacyRef<HTMLInputElement>
): React.ReactElement {
  // const [_checked, _setChecked] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [finalTabIndex, setTabIndex] = React.useState(tabIndex);

  let inputRef: HTMLInputElement;

  React.useEffect(() => {
    function focusInput() {
      inputRef.focus();
    }

    if (autoFocus) focusInput();
  }, [autoFocus, inputRef]);

  React.useEffect(() => {
    // force tabIndex to be -1 if component is in disabled state
    if (disabled) {
      setTabIndex(-1);
    } else {
      setTabIndex(tabIndex);
    }
  }, [disabled, tabIndex]);

  const handleFocus = React.useCallback(
    (ev: React.FocusEvent<HTMLInputElement>): void => {
      // disabled components should not receive focus
      // note: onFocus should never be called in the first place
      // buttons in these states should have a tabIndex of -1
      if (disabled) return;

      setFocused(true);
      if (onFocus) onFocus(ev);
    },
    [disabled, onFocus]
  );

  const handleBlur = React.useCallback(
    (ev: React.FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      if (onBlur) onBlur(ev);
    },
    [onBlur]
  );

  return (
    <RadioGroupContext.Consumer>
      {context => {
        const isChecked = context.value === value;

        function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
          // don't do anything if disabled
          if (disabled) return;

          onChange?.(!isChecked);
          context.onChange?.(ev.target.value);
          context.setValue?.(ev.target.value);
        }

        const blockClasses = classnames([
          blockName,
          { [`${blockName}--${variant}`]: variant },
          { [`${blockName}--size-${size}`]: size },
          { [`${blockName}--checked`]: isChecked },
          { [`${blockName}--disabled`]: disabled },
          { [`${blockName}--focus`]: focused || focus },
          { [`${blockName}--has-error`]: hasError },
          { [`${blockName}--has-warning`]: hasWarning },
          { [`${blockName}--has-success`]: hasSuccess },
          className || '',
        ]);

        const inputProps = {
          ...otherProps,
          ref: (_ref: HTMLInputElement) => {
            inputRef = _ref;
            return ref;
          },
          type: 'radio',
          name: name || context.name,
          value,
          checked: isChecked,
          disabled,
          className: `${blockName}__input`,
          onFocus: handleFocus,
          onBlur: handleBlur,
          onClick: handleChange,
          onChange: noop,
          tabIndex: finalTabIndex,
        };

        const labelProps = {
          disabled,
          className: blockClasses,
        };

        const RadioWrapper = (
          <div className={`${blockName}__wrapper`}>
            {/* @ts-ignore*/}
            <input ref={ref as React.LegacyRef<HTMLInputElement>} {...inputProps} />
            <div className={`${blockName}__border`} />
            <div className={`${blockName}__background`} />
          </div>
        );

        return (
          <label {...labelProps}>
            {labelBefore && <div className={`${blockName}__label-before`}>{labelBefore}</div>}
            {RadioWrapper}
            {label && (
              <div className={`${blockName}__label`}>
                <HTMLParser rawHtml={label} />
              </div>
            )}
          </label>
        );
      }}
    </RadioGroupContext.Consumer>
  );
}

export const Radio = React.forwardRef(RadioComp);

export const SwitchRadio = RadioComp;
