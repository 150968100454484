import { isEmailAddressAlreadyTaken } from '@gik/api/users/auth';
import { ExternalLoginProvider } from '@gik/auth/utils/LoginProvider';

export async function verifyEmailAndRedirectToLoginProvider(
  email: string,
  goToLoginStep: (email: string, firstName: string) => void,
  goToAccountDeactivatedStep: (email: string, firstName: string) => void,
  goToSignUpStep: (email: string) => void,
  goToOAuthStep: (email: string, provider: ExternalLoginProvider, firstName: string) => void
) {
  const status = await isEmailAddressAlreadyTaken(email);
  const provider = Object.values(ExternalLoginProvider).find(value => !!status[value]);

  if (status.deactivated) return void goToAccountDeactivatedStep(email, status.firstName);
  if (provider) return void goToOAuthStep(email, provider, status.firstName);
  if (status.exist) return void goToLoginStep(email, status.firstName);
  goToSignUpStep(email);
}
