import { getApplicationVersion } from '@gik/api/siteSettings/version';
import { useAppStore } from '@gik/core/store/AppStore';
import { checkAppVersion } from '@gik/core/utils/appUpdates';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import { CodeBlock } from '@gik/ui/CodeBlock';
import { Separator } from '@gik/ui/Separator';
import React from 'react';
import { useDevStore } from '../../store/DevStore';
import type { UIComponent } from '../../types/UI';
import getPreviewMode, { PreviewModeType, turnPreviewModeOff, turnPreviewModeOn } from '../../utils/PreviewMode';
import { DevToggleValue } from './components/DevToggleValue';

export type IAppToolsProps = {} & UIComponent;

export function AppTools({
  children,
  className,
  ...otherProps
}: React.PropsWithChildren<IAppToolsProps>): React.ReactElement {
  const bem = useBemCN('app-tools');

  const forceSkeletons = useDevStore(state => state.forceSkeletons);
  const setForceSkeletons = useDevStore(state => state.setForceSkeletons);

  const prefixNewPages = useDevStore(state => state.prefixNewPages);
  const setPrefixNewPages = useDevStore(state => state.setPrefixNewPages);

  const streamDebug = useDevStore(state => state.streamDebug);
  const setStreamDebug = useDevStore(state => state.setStreamDebug);

  const forceViewportFallbacks = useDevStore(state => state.forceViewportFallbacks);
  const setForceViewportFallbacks = useDevStore(state => state.setForceViewportFallbacks);

  const forceErrorBoundary = useDevStore(state => state.forceErrorBoundary);
  const setForceErrorBoundary = useDevStore(state => state.setForceErrorBoundary);

  const dirtyForms = useAppStore(state => state.dirtyForms);
  const isSubmitting = useAppStore(state => state.isSubmitting);
  const remoteAppVersion = useAppStore(state => state.remoteAppVersion);

  const blogPreviewMode = getPreviewMode(PreviewModeType.Blog);
  const setPreviewMode = (value: boolean) => {
    if (value) {
      turnPreviewModeOn(PreviewModeType.Blog);
    } else {
      turnPreviewModeOff(PreviewModeType.Blog);
    }
  };

  return (
    <div {...bem(null, null, className)} {...otherProps}>
      <DevToggleValue
        label="Force Render Viewport Fallbacks"
        value={forceViewportFallbacks}
        onChange={setForceViewportFallbacks}
      />
      <DevToggleValue label="Force Render Skeletons" value={forceSkeletons} onChange={setForceSkeletons} />
      <DevToggleValue label="Force Error boundary" value={forceErrorBoundary} onChange={setForceErrorBoundary} />
      <DevToggleValue label="Toggle blog Preview mode" value={blogPreviewMode} onChange={setPreviewMode} />
      <DevToggleValue
        label="Prefix new pages' name on Create Flow"
        value={prefixNewPages}
        onChange={setPrefixNewPages}
      />
      <DevToggleValue label="Enable Stream Debug view" value={streamDebug} onChange={setStreamDebug} />
      <Separator />
      App Version: {getApplicationVersion()} - Remote App Version: {remoteAppVersion}
      <br />
      <Button onClick={checkAppVersion}>Check App Version</Button>
      <br />
      <br />
      Dirty Forms
      <CodeBlock value={dirtyForms} title="Dirty Forms" />
      isSubmitting: {JSON.stringify(isSubmitting)}
    </div>
  );
}
