import type { CartItem, ShippingDetails } from '@gik/core/models/gik/Order';
import { CheckoutType } from '@gik/core/models/gik/Product';

export function validateSendToSelf(initialOrders: CartItem[], formEmail: string, shippingDetails: ShippingDetails) {
  return (
    initialOrders?.some(
      order => order.checkoutType === CheckoutType.TangoCard || order.checkoutType === CheckoutType.Perfectgift
    ) &&
    formEmail &&
    formEmail === shippingDetails?.email
  );
}
