import type { BreakpointType } from '@gik/core/hooks/CustomHooks';
import { breakpointBem } from '@gik/core/hooks/CustomHooks';
import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import { Container } from '@gik/ui/Container';
import React from 'react';
import type { UIGradient, UISectionDecoration } from '../../types';

export type PageSectionProps = {
  variant?: UIGradient | 'lightblue' | 'solid-neutral' | 'lightgray' | 'solid-neutral-100' | 'transparent' | 'white';
  gradientDarkening?: boolean;
  contained?: boolean;
  decoration?: UISectionDecoration;
  decorationPlacement?: 'left' | 'right' | 'both';
  defaultPadding?: boolean;
  noPad?: boolean;
  noPadBottom?: boolean;
  noPadTop?: boolean;
  /**
   * Custom background provided as a url
   */
  background?: string;
  backgroundSize?: 'cover' | 'contain';
  backgroundColor?: string;
  asElement?: 'section' | 'div' | 'header';
  inset?: boolean;
  name?: string;
  centered?: boolean;
} & UIComponent &
  BreakpointType;

function PageSectionComp({
  children,
  className,
  variant,
  gradientDarkening,
  decoration,
  decorationPlacement = 'both',
  defaultPadding,
  noPad,
  noPadBottom,
  noPadTop,
  contained = true,
  asElement = 'section',
  inset,
  name,
  breakpoint,
  background,
  backgroundSize = 'contain',
  backgroundColor,
  centered = false,
  ...otherProps
}: React.PropsWithChildren<PageSectionProps>): React.ReactElement {
  const bem = bemBlock('page-section');
  const blockClasses = bem(
    null,
    [
      variant,
      { [`decoration-${decoration}`]: decoration },
      { [`decoration-${decorationPlacement}`]: decoration && decorationPlacement },
      { [`gradient-darkening`]: gradientDarkening },
      { [`default-pad`]: defaultPadding },
      { [`no-pad`]: noPad },
      { [`no-pad-bottom`]: noPadBottom },
      { [`no-pad-top`]: noPadTop },
      { [`inset`]: inset },
      { [`has-background`]: background },
      breakpointBem(breakpoint),
    ],
    className
  );

  const Element = asElement;

  const styles = {
    ...otherProps.style,
    '--background': background ? `url('${background}')` : null,
    '--background-size': background ? backgroundSize : null,
    backgroundColor,
  };

  // FIXME: fix base components inheritance
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <Element {...(otherProps as any)} className={blockClasses} name={name} style={styles}>
      {contained && (
        <Container centered={centered} breakpoint={breakpoint}>
          {children}
        </Container>
      )}
      {!contained && children}
    </Element>
  );
}

export const PageSection = PageSectionComp;
