import type { UIComponent } from '@gik/core/types/UI';
import bemBlock from '@gik/core/utils/bemBlock';
import noop from '@gik/core/utils/noop';
import type { HeadingLevel } from '@gik/ui/Heading';
import { Heading } from '@gik/ui/Heading';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';
import React from 'react';

export type AccordionItemProps = {
  header?: React.ReactNode;
  isOpen?: boolean;
  onToggle?(ev: React.MouseEvent<HTMLDivElement>): void;
  onClick?(ev: React.MouseEvent<HTMLDivElement>): void;
  headerAsHeading?: boolean;
  headingLevel?: HeadingLevel;
} & UIComponent;

export const AccordionItem: React.FC<React.PropsWithChildren<AccordionItemProps>> = React.memo(
  ({
    header,
    children,
    className,
    isOpen,
    onToggle = noop,
    onClick = noop,
    headerAsHeading = false,
    headingLevel = 2,
    ...otherProps
  }) => {
    const bem = bemBlock('accordion-item');

    const handleToggle = React.useCallback(
      (ev: React.MouseEvent<HTMLDivElement>) => {
        if (!children) {
          onClick(ev);
          return;
        }

        onToggle(ev);
      },
      [children, onClick, onToggle]
    );

    return (
      <div className={bem(null, [{ open: isOpen }], className)} {...otherProps}>
        {header && (
          <section className={bem('header')} onClick={handleToggle}>
            {headerAsHeading ? (
              <Heading level={headingLevel} noStyling className={bem('title')}>
                {header}
              </Heading>
            ) : (
              <span className={bem('title')}>{header}</span>
            )}
            {children && (
              <div className={bem('toggle-icon')}>
                <SvgIcon Icon={ChevronDownIcon} size="sm" />
              </div>
            )}
          </section>
        )}
        <section className={bem('main')}>{children && <div>{children}</div>}</section>
      </div>
    );
  }
);
