import type { ICalendarEventClaimCreatePayload } from '@gik/api/calendar/calendarClaims';
import type { ICalendarEntry } from '@gik/calendar/models/Calendar';
import type { CardCarrierFormValues } from '@gik/checkout/components/CardCarrier/CardCarrierForm';
import type { ClaimStatus } from '@gik/checkout/types';
import type { PerfectGiftFaceplate } from '@gik/core/models/perfectgift/faceplate';
import type { IPerfectgiftFaceplateUploaderResult } from '@gik/shop/components/PerfectgiftFaceplateButtons/PerfectgiftFaceplateButtons';
import type { OLProductType } from '@gik/shop/components/products/ProductSection/ProductSection';
import type { ProductCheckoutType } from './Product';

// TODO: move this to models project
export enum OrderCreationStatus {
  Pending,
  Success,
  Fail,
}

export enum OrderClaimStatus {
  None, // some orders are not for the calendar
  Success,
  Conflict,
  Gone,
  Error,
}

export enum PerfectgiftCarrierTypes {
  GREETING_CARD = 'greeting_card',
  TRIFOLD_NO_ENVELOPE = 'trifold_no_envelope',
  TRIFOLD_WITH_ENVELOPE = 'trifold_with_envelope',
}

interface PerfectgiftCartItem {
  productType?: OLProductType;
  nameOnCard?: string;
  customMessage?: string;
  uploadPhoto?: IPerfectgiftFaceplateUploaderResult;
  faceplate?: PerfectGiftFaceplate;
  expiryMonth?: string;
}

export interface CartItem extends PerfectgiftCartItem {
  id?: string;
  index?: number;
  productSlug: string;
  productId: number;
  variationId?: number;
  groupedProductIds?: number[]; // for "Grouped" products - e.g. gift box + gift card bundle
  quantity?: number;
  price?: number;
  name: string;
  checkoutType: ProductCheckoutType;
  anonymous?: boolean;
  claimEntry?: ICalendarEntry;
  createClaimRequest?: ICalendarEventClaimCreatePayload;
  carrier?: CardCarrierFormValues;
  isWishlist?: boolean;
}

export interface ShippingDetails {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  email?: string;
  shippingOptionName?: string;
  shippingValue?: string; // used for PG shipping
  deliveryMethod?: string;
}

export interface BillingDetails {
  firstName?: string;
  lastName?: string;
  companyName?: string;
  country?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  email?: string;
}

export default interface Order {
  id: number;
  creationStatus: OrderCreationStatus;
  creationStatusMessage: string;
  errorMessage: string;
  subtotal: number;
  total: number;
  shippingCost: number;
  taxCost: number;

  orderDate: string;
  senderEmail: string;
  paymentMethod: string;
  tip: number;
  shipping: ShippingDetails;
  products: CartItem[]; // TODO: rename to cartItems (requires backend change)
  // claim as part of order
  // claimStatus?: OrderClaimStatus; // someone else claimed the item immediately beforehand
  claims: ClaimStatus[];
  claimErrorMessage?: string;
  // required for 3d secure payments
  paymentIntentClientSecret?: string;
}
